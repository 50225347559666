import configs from "@global/configs";
import axios from "axios";

const API = configs.api;

export const getFood = async ({ id }) =>
  await axios
    .get(`${API}/food/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getAllFood = async () =>
  await axios
    .get(
      `${API}/food`,
      {
        params: {
          attributes: "id,name,preview_image,is_published,updated_at",
        },
      },
      {
        withCredentials: true,
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createFood = async (data = {}) =>
  await axios
    .post(`${API}/food/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateFood = async (data = {}) =>
  await axios
    .post(`${API}/food/update/${data.id}`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteFood = async (data = {}) =>
  await axios
    .delete(`${API}/food/id/${data.id}`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getAttributes = async () =>
  await axios
    .get(`${API}/food/attributes`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getFoodWineMatch = async ({ id }) =>
  await axios
    .get(`${API}/match/food/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });
