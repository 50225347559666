import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Tabs, Tag, Form, Input, message, Popconfirm, Divider, Switch} from 'antd';
import {SaveOutlined, DeleteOutlined, SyncOutlined} from '@ant-design/icons';
import {Users} from '@api';
import { pathOr } from 'ramda';

const {TabPane} = Tabs;

export default class EditUser extends PureComponent{

  state = {
    isLoading: true,
    new_password: null,
    user: {
      id: null,
      first_name: null,
      last_name: null,
      email: null,
      contacts: null,
      is_admin: null,
      is_blocked: null,
      updated_at: null,
    },
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    const _res = await Users.getUser({id});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      user: _res.data.user
    });

  }

  onSave = async values => {
    const _res = await Users.updateUser({
      id: this.state.user.id,
      ...values,
    });

    if(_res.error){
      message.error(_res.message || 'Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onPasswordReset = async () => {

    const {user} = this.state;

    if(!user.email){
      message.error('No email found for this user. Save changes before requesting password reset.', 3);
      return;
    }

    this.setState({
      isLoading: true
    });

    const _resPasswordReset = await Users.resetPasswordAdmin({
      email: user.email,
    });

    this.setState({
      isLoading: false,
      new_password: pathOr(null, ['data', 'new_password'],_resPasswordReset)
    });

    if(_resPasswordReset.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;
  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Users.deleteUser({id: this.state.user.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error(deleted.message || "Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/users";
    });

  }

  render(){

    const {isLoading, user, new_password} = this.state;
    
    return <div className="row ">

      <div className="col-12 col-xl-8">
        <Card loading={isLoading}>

          <Form
            name="user"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={user}
          >

            <PageHeader
              title="Edit User"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Form.Item
              label="User is blocked?"
              name="is_blocked"
            >
              <Switch defaultChecked={user.is_blocked} />
            </Form.Item>

            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: 'Please input first name' }]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="last_name"
            >
              <Input placeholder="Enter first name" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input email' }]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong></p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

      <div className="col-12 col-xl-4">
        <Card title="Password">

          <h3>Reset password</h3>
          <p>You can request to reset this user's password. New password will be sent to the user's email and shown here only once.</p>

          {new_password && <p className="m-0"><strong>New password:</strong> <Tag color="orange">{new_password}</Tag></p>}

          <Button loading={isLoading} onClick={this.onPasswordReset} style={{marginTop: 20}} type="danger" icon={<SyncOutlined />}>Reset Password</Button>

        </Card>
      </div>

    </div>
  }

}