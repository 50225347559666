import configs from '@global/configs';
import axios from 'axios';

const API = configs.api;

export const getProjects = async () => await axios.get(`${API}/projects`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const getProject = async ({id}) => await axios.get(`${API}/projects/id/${id}`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const createProject = async (data = {}) => await axios.post(`${API}/projects/create`, data, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {

    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
    
  });

export const updateProject = async (data = {}) => await axios.post(`${API}/projects/update/${data.id}`, data, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {

    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
    
  });

export const deleteProject = async (data = {}) => await axios.delete(`${API}/projects/id/${data.id}`, data, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {

    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
    
  });