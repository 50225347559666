import configs from '@global/configs';
import axios from 'axios';

const API = configs.api;

export const getPages = async () => await axios.get(`${API}/pages/native`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const getPage = async ({page_id}) => await axios.get(`${API}/pages/native/pageid/${page_id}`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const updatePage = async (data = {}) => await axios.post(`${API}/pages/native/update/${data.id}`, data, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {

    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
    
  });