import React, { PureComponent } from 'react';
import {PageHeader, Button, Select, Card, Tabs, Form, Input, message, Popconfirm, Divider, Upload} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined, InboxOutlined} from '@ant-design/icons';
import {Attributes, StaticAPI} from '@api';
import {pathOr} from 'ramda';
import configs from '@global/configs';

const {Dragger} = Upload;
const {Option} = Select;
const {TabPane} = Tabs;

export default class EditRegion extends PureComponent{

  state = {
    isLoading: true,
    countries: [],
    region: {
      id: null,
      name: null,
      country_id: null,
      updated_at: null,
    },
    previewImageList: [],
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    const _res = await Attributes.getRegion({id});
    const _resCountries = await Attributes.getCountries();

    if(_res.error || _resCountries.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    const previewImageList = pathOr(null, ['data', 'region', 'mapimg'], _res) && [{
      uid: `uid-image-1`,
      url: `${configs.api}/static/img/${_res.data.region.mapimg}`,
      name: 'Map Image',
      status: 'done',
      response: {
        data: {
          filename: _res.data.region.mapimg
        }
      }
    }] || [];

    this.setState({
      isLoading: false,
      region: _res.data.region,
      countries: _resCountries.data.countries,
      previewImageList
    });

  }

  onSingleImageUpload = (info, field) => {
    const { status, response } = info.file;

    this.setState({
      [field]: [{
        ...info.file,
        url: `${configs.api}/static/img/${pathOr('', ['response', 'data', 'filename'], info.file)}`
      }]
    })

    if (status === 'done') {

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }

    if(status ==='removed'){
      this.setState({
        [field]: [],
      })
    }
  }

  onSingleImageRemove = async (e, field) => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      this.setState({
        [field]: []
      })

      message.success("File deleted", 1)
    }

  }

  onSave = async values => {

    const mapimg = pathOr('', ['previewImageList', 0, 'response', 'data', 'filename'], this.state);

    const _res = await Attributes.updateRegion({
      id: this.state.region.id,
      ...values,
      mapimg
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Attributes.deleteRegion({id: this.state.region.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/attributes/regions";
    });

  }

  getCountryOptions = () => {
    const {countries} = this.state;

    return countries.map(c =>{
      return <Option key={c.id} value={c.id} label={pathOr('N/a', ['name', 'en'], c)}>{pathOr('N/a', ['name', 'en'], c)}</Option>
    })
  }

  render(){

    const {isLoading, region, previewImageList} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="region"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={region}
          >

            <PageHeader
              title="Edit Region"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Tabs defaultActiveKey="en" >
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Name"
                  name={['name', 'en']}
                  rules={[{ required: true, message: 'Please input region name' }]}
                >
                  <Input placeholder="Enter region name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Name"
                  name={['name', 'ru']}
                >
                  <Input placeholder="Enter region name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Name"
                  name={['name', 'es']}
                >
                  <Input placeholder="Enter region name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
               <Form.Item
                  label="Name"
                  name={['name', 'cn']}
                >
                  <Input placeholder="Enter region name" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Form.Item
              label="Country"
              name="country_id"
              rules={[{ required: true, message: 'Please choose country' }]}
            >
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select country"
                allowClear
                optionFilterProp="label"
              >{this.getCountryOptions()}</Select>
            </Form.Item>

            <Divider />

            <h3>Region Map Image</h3>
            <p>Upload region image (optional). This will be shown in Wine Details page.</p>

            <Form.Item name="mapimg" noStyle>
              <Dragger
                listType="picture"
                fileList={previewImageList}
                onChange={e => this.onSingleImageUpload(e, 'previewImageList')}
                onRemove={e => this.onSingleImageRemove(e, 'previewImageList')}
                withCredentials
                multiple={false}
                accept="image/*"
                name="img"
                action={`${configs.api}/static/img/upload`}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Upload Image</p>
              </Dragger>
            </Form.Item>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}