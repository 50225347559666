import React, {PureComponent} from 'react';
import moment from 'moment';
import {PageHeader, Card, Button, message, Divider, Breadcrumb, Table, Tag, Input, Space, Badge} from 'antd';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';
import {EyeOutlined, SearchOutlined} from '@ant-design/icons';
import {Leads} from '@api';
import {pathOr, propEq, filter} from 'ramda';

export default class LeadsPage extends PureComponent{

  state = {
    _isLoading: true,
    leads: [],
    searchText: '',
    searchedColumn: '',
  }

  async componentDidMount(){
    const _resLeads = await Leads.getLeads();

    if(_resLeads.error){
      message.error(_resLeads.message || "Can't complete request due to server error. Try later.", 3)
      return;
    }

    this.setState({
      _isLoading: false,
      leads: _resLeads.data.leads,
    })
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  getTableData = () => {
    const {leads} = this.state;

    return leads.map((data, i) => {

      let status_id = 1;
      let status = <Tag color="red">New</Tag>;

      switch (data.status) {
        case 'processing':
          status_id = 2;
          status = <Tag color="processing">Processing</Tag>;
          break;
        case 'completed':
          status_id = 3;
          status = <Tag color="success">Completed</Tag>;
          break;
        default:
          break;
      }

      return {
        key: data.id,
        id: data.id,
        bitrix_id: pathOr('N/a', ['bitrix_id'], data),
        name: pathOr('N/a', ['name'], data),
        tel: pathOr('N/a', ['tel'], data),
        email: pathOr('N/a', ['email'], data),
        status,
        status_id,
        created_at: moment(data.created_at).utc().format('MM/DD/YYYY HH:mm'),
        updated_at: moment(data.updated_at).utc().format('MM/DD/YYYY HH:mm'),
        extra: data.id
      }
    })
  }

  newLeadsCount = () => {
    const {leads} = this.state;

    if(leads.count === 0){
      return 0;
    }

    return leads.filter(propEq('status', 'new'), leads).length || 0;
  }

  render(){

    const tablecols = [
      {
        key: 'id',
        dataIndex: 'ID',
        dataIndex: 'id',
        render: data => <strong>{data}</strong>
      },
      {
        key: 'bitrix_id',
        title: 'Bitrix24 ID',
        dataIndex: 'bitrix_id',
        sorter: (a, b) => a.bitrix_id - b.bitrix_id,
        render: data => <Tag>{data}</Tag>,
      },
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        ...this.getColumnSearchProps('name'),
        render: data => <strong className="m-0">{data}</strong>,
      },
      {
        key: 'tel',
        title: 'Phone No.',
        dataIndex: 'tel',
        sorter: (a, b) => { return a.tel.localeCompare(b.tel)},
        ...this.getColumnSearchProps('tel'),
        render: data => <Tag>{data || 'N/a'}</Tag>,
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => { return a.email.localeCompare(b.email)},
        ...this.getColumnSearchProps('email'),
        render: data => data !== 'N/a' ? <Tag color="processing"><a href={`mailto:${data}`}>{data}</a></Tag> : <Tag>N/a</Tag>,
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        sorter: (a, b) => a.status_id - b.status_id,
        render: data => data,
      },
      {
        key: 'updated_at',
        title: 'Last Update',
        dataIndex: 'updated_at',
        sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'extra',
        dataIndex: 'extra',
        align: 'right',
        render: id => <Link to={`/leads/edit/${id}`}><Button icon={<EyeOutlined />}>View</Button></Link>
      },
    ]

    return <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Leads from Website</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          style={{padding: 0}}
          title="Leads from Website"
          subTitle={<Badge count={this.newLeadsCount()} />}
        />
        <Divider />

        <Table pagination={{showSizeChanger: true}} loading={this.state._isLoading} columns={tablecols} dataSource={this.getTableData()} />
      </Card>
    </div>
  }
}