import React, { PureComponent } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import slugify from 'slugify';
import {Link} from 'react-router-dom';
import {PageHeader, Button, Card, Tabs, Select, Form, Input, message, Divider, Switch, Upload, Collapse, Popconfirm} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, InboxOutlined, DeleteOutlined} from '@ant-design/icons';
import {Attributes, Countries, Projects, Food, Producers, StaticAPI, Wines} from '@api';
import {mapObjIndexed, propEq, pathOr, without, find} from 'ramda';
import configs from '@global/configs';

const {TextArea} = Input;
const {TabPane} = Tabs;
const {Panel} = Collapse;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;

export default class EditFood extends PureComponent{

  state = {
    isLoading: true,
    food: {
      id: null,
      name: '',
      slug: '',
      description: null,
      ingredients: null,
      allergy_information: null,
      storage: '',
      preview_image: '',
      media: [],
      producer_country_id: null,
      producer_id: null,
      colour_id: null,
      project_id: null,
      own_production: false,
      volume: null,
      is_feautred: false,
      is_published: false,
    },
    producers: [],
    countries: [],
    attributes: [],
    foodAttributes: [],
    imgFileList: [],
    previewFilelist: [],
    foodWineMatch: [],
    wines: [],
  }

  async componentDidMount(){
    const id = this.props.match.params.id;

    const food = await Food.getFood({id});
    const wines = await Wines.getWines();
    const foodWineMatch = await Food.getFoodWineMatch({id});
    const attributes = await Attributes.getAttributes();
    const foodAttributes = await Attributes.getFoodAttributes({food_id: id});
    const projects = await Projects.getProjects();
    const producers = await Producers.getProducers();
    const countries = await Countries.getCountries();

    if(food.error ||
      attributes.error ||
      foodAttributes.error ||
      projects.error ||
      producers.error ||
      countries.error ||
      wines.error ||
      foodWineMatch.error
      ){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }
    
    food.data.food.media = food.data.food.media || [];   

    const imgFileList = food.data.food.media.map((img, i)=>{
      return {
        uid: `uid-${i}`,
        url: configs.api + '/static/img/' + img,
        name: `Image ${i+1}`,
        status: 'done',
        response: {
          data: {
            filename: img
          }
        }
      }
    });

    const previewFilelist = food.data.food.preview_image && [{
      uid: `uid-image-1`,
      url: `${configs.api}/static/img/${food.data.food.preview_image}`,
      name: 'Preview Image',
      status: 'done',
      response: {
        data: {
          filename: food.data.food.preview_image
        }
      }
    }] || [];

    this.setState({
      isLoading: false,
      food: food.data.food,
      attributes: attributes.data.attributes,
      foodAttributes: foodAttributes.data.food_attributes,
      projects: projects.data.projects,
      producers: producers.data.producers,
      countries: countries.data.countries,
      foodWineMatch: foodWineMatch.data,
      imgFileList,
      wines: wines.data.wines,
      previewFilelist
    });

  }

  onSave = async values => {
    const media = pathOr([], ['media'], this.state.food);
    const preview_image = pathOr(null, ['preview_image'], this.state.food);
    const slug = slugify(values.name) || '';

    values.attributes = mapObjIndexed(a => a || null, values.attributes);

    const _foodUpdate = await Food.updateFood({
      ...values,
      id: this.state.food.id,
      slug: slug.toLowerCase(),
      project_id: values.project_id || null,
      media,
      match: values.match || [],
      preview_image
    })

    if(_foodUpdate.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  getCountryOptions = () => {
    const {countries} = this.state;
    
    if(countries.length === 0){
      return [];
    }

    return countries.map(c=>{
      return {
        value: c.id,
        label: c.name.en
      }
    })
  }

  getProducerOptions = () => {
    const {producers} = this.state;
    
    if(producers.length === 0){
      return [];
    }

    return producers.map(c=>{
      return {
        value: c.id,
        label: c.name
      }
    })
  }

  getProjectOptions = () => {
    const {projects} = this.state;
    
    if(projects.length === 0){
      return [];
    }

    return projects.map(c=>{
      return {
        value: c.id,
        label: c.name || 'No name'
      }
    })
  }

  getPanelOptions = () => {
    const {attributes, foodAttributes} = this.state;
    const getInput = panel => {

      switch (panel.type) {
        case 'tags':

          let vals = pathOr([], ['value'], find(propEq('attribute_id', panel.id))(foodAttributes));

          try {
            vals = JSON.parse(vals);
          } catch (error) {
            console.log('Not JSON');
          }

          return <Form.Item
            label={pathOr('N/a', ['name', 'en'], panel)}
            name={['attributes', 'attr-'+panel.id]}
            initialValue={vals}
          >
            <Select mode="tags" showSearch optionFilterProp="label" allowClear style={{ width: '100%' }} placeholder="Choose one or type">
              {panel.defaults.map((d,i)=>{
                return <Option key={i} value={d} label={d}>{d}</Option>
              })}
            </Select>
          </Form.Item>

        case 'select':

          let selectNormalized = pathOr([], ['value'], find(propEq('attribute_id', panel.id))(foodAttributes));

          try {
            selectNormalized = JSON.parse(selectNormalized)[0];
          } catch (error) {
            console.log('Not JSON');
          }

          if(selectNormalized === 'null'){
            selectNormalized = undefined;
          }

          return <Form.Item
            label={pathOr('N/a', ['name', 'en'], panel)}
            name={['attributes', 'attr-'+panel.id]}
            initialValue={selectNormalized}
          >
            <Select showSearch optionFilterProp="label" allowClear style={{ width: '100%' }} placeholder="Choose one">
              {panel.defaults.map((d,i)=>{
                return <Option key={i} value={[d]} label={d}>{d}</Option>
              })}
            </Select>
          </Form.Item>

        case 'text':
          return <Form.Item
            label={pathOr('N/a', ['name', 'en'], panel)}
            name={['attributes', 'attr-'+panel.id]}
            initialValue={pathOr('', ['value'], find(propEq('attribute_id', panel.id))(foodAttributes))}
          >
            <TextArea rows={4} placeholder={`Enter details for ${pathOr('N/a', ['name', 'en'], panel)}`}></TextArea>
          </Form.Item>
      
        default:
          break;
      }
    }

    return attributes.map((a, i)=>{
      return <Panel forceRender key={i} header={pathOr('N/a', ['name', 'en'], a)}>
        {getInput(a)}
      </Panel>
    });
  }

  getProjectImages = () => {
    const {images} = this.state.project;

    if(!images){
      return []
    }

    return images.map((img, i)=>{
      return {
        uid: `uid-${img.id}`,
        id: img.id,
        name: `Image ${i+1}`,
        status: 'done',
        url: `${configs.api}/${img.url}`
      }
    });
  }

  onImageUploadChange = info => {
    const { status, response } = info.file;

    this.setState({
      imgFileList: [...info.fileList]
    })

    if (status === 'done') {
      
      let {media} = this.state.food;
      
      if(!media){
        media = []
      }

      media.push(response.data.filename);

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }

      this.setState({
        food: {
          ...this.state.food,
          media
        }
      })
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }
  }

  onPreviewUploadChange = info => {
    const { status, response } = info.file;

    this.setState({
      previewFilelist: [{
        ...info.file,
        url: `${configs.api}/static/img/${pathOr('', ['response', 'data', 'filename'], info.file)}`
      }]
    })

    if (status === 'done') {

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }

      this.setState({
        food: {
          ...this.state.food,
          preview_image: response.data.filename
        }
      })
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }

    if(status ==='removed'){
      this.setState({
        previewFilelist: []
      })
    }
  }

  onImageRemove = async e => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      const media = this.state.food.media || [];
      const newMedia = without([name], media);

      const foodUpdated = await Food.updateFood({
        id: this.state.food.id,
        media: newMedia
      });

      this.setState({
        food: {
          ...this.state.food,
          media: newMedia
        }
      })

      message.success("File deleted", 1)
    }

  }

  onPreviewRemove = async e => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      const _foodUpdated = await Food.updateFood({
        id: this.state.food.id,
        preview_image: ''
      });

      if(_foodUpdated.error){
        message.error("Server error, please try again")
        return;
      }

      this.setState({
        food: {
          ...this.state.food,
          preview_image: ''
        },
        previewFilelist: []
      })

      message.success("File deleted", 1)
    }

  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Food.deleteFood({id: this.state.food.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/food";
    });

  }

  getMatchOptions = () => {
    const {wines} = this.state;

    return wines.map(wine => {
      return {
        value: wine.id,
        label: `ID-${wine.id}: ${wine.name}`
      }
    });
  }

  render(){

    const {isLoading, food, imgFileList, previewFilelist, foodWineMatch} = this.state;

    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="food"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={food}
          >

            <PageHeader
              title="Edit Food Product"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Is published?"
                  name="is_published"
                >
                  <Switch defaultChecked={food.is_published || false} />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Own production?"
                  name="own_production"
                >
                  <Switch defaultChecked={food.own_production || false} />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input food name' }]}
            >
              <Input size="large" placeholder="Enter food name" />
            </Form.Item>

            <Tabs defaultActiveKey="en" >
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Description"
                  name={['description', 'en']}
                  rules={[{ required: true, message: 'Please input food info' }]}
                >
                  <TextArea rows={4} placeholder="Enter food info" />
                </Form.Item>
                <Form.Item
                  label="Ingredients (content)"
                  name={['ingredients', 'en']}
                >
                  <TextArea rows={4} placeholder="Enter ingredients info" />
                </Form.Item>
                <Form.Item
                  label="Allergy Information"
                  name={['allergy_information', 'en']}
                >
                  <TextArea rows={4} placeholder="Enter allergy info" />
                </Form.Item>
                <Form.Item
                  label="Storage Information"
                  name={['storage', 'en']}
                >
                  <TextArea rows={4} placeholder="Enter storage info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Description"
                  name={['description', 'ru']}
                >
                  <TextArea rows={4} placeholder="Enter food info" />
                </Form.Item>
                <Form.Item
                  label="Ingredients (content)"
                  name={['ingredients', 'ru']}
                >
                  <TextArea rows={4} placeholder="Enter ingredients info" />
                </Form.Item>
                <Form.Item
                  label="Allergy Information"
                  name={['allergy_information', 'ru']}
                >
                  <TextArea rows={4} placeholder="Enter allergy info" />
                </Form.Item>
                <Form.Item
                  label="Storage Information"
                  name={['storage', 'ru']}
                >
                  <TextArea rows={4} placeholder="Enter allergy info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Description"
                  name={['description', 'es']}
                >
                  <TextArea rows={4} placeholder="Enter food info" />
                </Form.Item>
                <Form.Item
                  label="Ingredients (content)"
                  name={['ingredients', 'es']}
                >
                  <TextArea rows={4} placeholder="Enter ingredients info" />
                </Form.Item>
                <Form.Item
                  label="Allergy Information"
                  name={['allergy_information', 'es']}
                >
                  <TextArea rows={4} placeholder="Enter allergy info" />
                </Form.Item>
                <Form.Item
                  label="Storage Information"
                  name={['storage', 'es']}
                >
                  <TextArea rows={4} placeholder="Enter allergy info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Description"
                  name={['description', 'cn']}
                >
                  <TextArea rows={4} placeholder="Enter food info" />
                </Form.Item>
                <Form.Item
                  label="Ingredients (content)"
                  name={['ingredients', 'cn']}
                >
                  <TextArea rows={4} placeholder="Enter ingredients info" />
                </Form.Item>
                <Form.Item
                  label="Allergy Information"
                  name={['allergy_information', 'cn']}
                >
                  <TextArea rows={4} placeholder="Enter allergy info" />
                </Form.Item>
                <Form.Item
                  label="Storage Information"
                  name={['storage', 'cn']}
                >
                  <TextArea rows={4} placeholder="Enter allergy info" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Slug"
                  name="slug"
                >
                  <Input disabled placeholder="(generates automatically)" style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Project"
                  name="project_id"
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getProjectOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Producer"
                  name="producer_id"
                  rules={[{ required: true, message: 'Please choose producer' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getProducerOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Producer Country"
                  name="producer_country_id"
                  rules={[{ required: true, message: 'Please choose an option' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getCountryOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Volume/Pack Sizes"
                  name="volume"
                >
                  <Select showSearch allowClear mode="tags" optionFilterProp="label" style={{ width: '100%' }} placeholder="Type volume/pack sizes (e.g. 100g or 0,75L)" />
                </Form.Item>
              </div>
            </div>

            <Divider />

            <h3>Images</h3>
            <p style={{marginBottom: 20}}>Add preview and additional images</p>

            <div className="row">
              <div className="col-12 col-lg-4">
               <Form.Item label="Preview Image">
                  <Form.Item name="preview_image" noStyle>
                    <Upload
                      listType="picture-card"
                      fileList={previewFilelist}
                      withCredentials
                      accept="image/*"
                      name="img"
                      action={`${configs.api}/static/img/upload`}
                      onChange={this.onPreviewUploadChange}
                      onRemove={this.onPreviewRemove}
                    >
                      {previewFilelist.length === 0 && <div className="ant-upload-text">Upload</div>}
                    </Upload>
                  </Form.Item>
                </Form.Item>
              </div>
              <div className="col-12 col-lg-8">
                <Form.Item label="Images">
                  <Form.Item name="media" noStyle>
                    <Dragger
                      listType="picture"
                      fileList={imgFileList}
                      onChange={this.onImageUploadChange}
                      onRemove={this.onImageRemove}
                      withCredentials
                      multiple
                      accept="image/*"
                      name="img"
                      action={`${configs.api}/static/img/upload`}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Upload up to 3 images.</p>
                    </Dragger>
                  </Form.Item>
                </Form.Item>
              </div>
            </div>

            <Divider />

            <Form.Item
              label="Recommended wines with this product"
              name="match"
              initialValue={foodWineMatch.ids || []}
            >
              <Select showSearch allowClear options={this.getMatchOptions()} mode="multiple" optionFilterProp="label" style={{ width: '100%' }} placeholder="Choose wines to recommend with this product" />
            </Form.Item>

            <Divider />

            <h3>Additional information</h3>
            <p style={{marginBottom: 20}}>Edit <Link to="/attributes/custom">additional</Link> food/wine attributes.</p>

            <Collapse defaultActiveKey={['0']} style={{margin: '25px 0 50px 0'}}>
              {this.getPanelOptions()}
            </Collapse>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}