import configs from "@global/configs";
import axios from "axios";

const API = configs.api;

export const getUsers = async () =>
  await axios
    .get(`${API}/users`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const getCurrentUser = async () =>
  await axios
    .get(`${API}/users/current`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const getUser = async ({ id }) =>
  await axios
    .get(`${API}/users/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const signOut = async () =>
  await axios
    .get(`${API}/auth/signout`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const isAdminSignedIn = async () =>
  await axios
    .get(`${API}/auth/check`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const doSignIn = async (data) =>
  await axios
    .post(`${API}/auth/signin`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const deleteUser = async (data = {}) =>
  await axios
    .delete(`${API}/users/id/${data.id}`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const resetPassword = async (data) =>
  await axios
    .post(`${API}/users/update/password/reset`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const resetPasswordAdmin = async (data) =>
  await axios
    .post(`${API}/users/update/password/reset?from=admin`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const createUser = async (data = {}) =>
  await axios
    .post(`${API}/users/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const updateUser = async (data = {}) =>
  await axios
    .post(`${API}/users/update/details/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });

export const updatePassword = async (data = {}) =>
  await axios
    .post(`${API}/users/update/password/self/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.message || "Server request error",
      };
    });
