import configs from '@global/configs';
import axios from 'axios';

const API = configs.api;

export const getKeys = async () => await axios.get(`${API}/keys`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const getKey = async ({id}) => await axios.get(`${API}/keys/type/${id}`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const updateKey = async (data = {}) =>
  await axios
    .post(`${API}/keys/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });
