import configs from "@global/configs";
import axios from "axios";

const API = configs.api;

export const getWine = async ({ id }) =>
  await axios
    .get(`${API}/wines/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getWines = async () =>
  await axios
    .get(
      `${API}/wines`,
      {
        params: {
          attributes: "id,name,preview_image,is_published,updated_at",
        },
      },
      {
        withCredentials: true,
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createWine = async (data = {}) =>
  await axios
    .post(`${API}/wines/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateWine = async (data = {}) =>
  await axios
    .post(`${API}/wines/update/${data.id}`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteWine = async (data = {}) =>
  await axios
    .delete(`${API}/wines/id/${data.id}`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getAppellations = async () =>
  await axios
    .get(`${API}/wines/appellations`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getAttributes = async () =>
  await axios
    .get(`${API}/wines/attributes`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getColours = async () =>
  await axios
    .get(`${API}/wines/colours`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getTypes = async () =>
  await axios
    .get(`${API}/wines/types`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getWineFoodMatch = async ({ id }) =>
    await axios
      .get(`${API}/match/wines/id/${id}`, { withCredentials: true })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        if (!error.response.data) {
          return {
            error: true,
            message: "Server request error",
          };
        }
  
        return {
          error: true,
          message: error.response.data.message || "Server request error",
        };
      });
  