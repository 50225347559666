import React, {PureComponent} from 'react';
import moment from 'moment';
import {PageHeader, Card, Button, message, Divider, Breadcrumb, Table, Tag, Input, Space} from 'antd';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';
import {PlusOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import {Projects} from '@api';
import configs from '@global/configs';
import {pathOr} from 'ramda';

export default class ProjectsPage extends PureComponent{

  state = {
    _isLoading: true,
    projects: [],
    searchText: '',
    searchedColumn: '',
  }

  async componentDidMount(){
    const _res = await Projects.getProjects();
    if(_res.error){
      message.error(_res.message || "Can't complete request due to server error. Try later.", 3)
      return;
    }

    this.setState({
      _isLoading: false,
      projects: _res.data.projects
    })
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  getTableData = () => {
    const {projects} = this.state;

    return projects.map((p, i) => {
      return {
        key: p.id,
        id: p.id,
        logo: p.logo ? <img className="list-logo-img" src={`${configs.api}/static/img/${p.logo}`} /> : <img className="list-logo-img" src="/img/no-image.png" />,
        name: p.name || 'N/a',
        country: pathOr('N/a', ['country.name', 'en'], p),
        classification: pathOr('N/a', ['classification.name'], p),
        region: pathOr('N/a', ['region.name', 'en'], p),
        published: p.is_published,
        updated_at: moment(p.updated_at).utc().format('MM/DD/YYYY HH:mm'),
        extra: p.id
      }
    })
  }

  render(){

    const tablecols = [
      {
        key: 'logo',
        title: 'Logo',
        dataIndex: 'logo',
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: data => <strong className="m-0">{data}</strong>,
        ...this.getColumnSearchProps('name'),
      },
      {
        key: 'country',
        title: 'Country',
        dataIndex: 'country',
        sorter: (a, b) => { return a.country.localeCompare(b.country)},
        render: data => <p className="m-0">{data}</p>,
        ...this.getColumnSearchProps('country'),
      },
      {
        key: 'region',
        title: 'Region',
        dataIndex: 'region',
        sorter: (a, b) => { return a.region.localeCompare(b.region)},
        render: data => <p className="m-0">{data}</p>,
        ...this.getColumnSearchProps('region'),
      },
      {
        key: 'classification',
        title: 'Classification',
        dataIndex: 'classification',
        sorter: (a, b) => { return a.classification.localeCompare(b.classification)},
        render: data => <p className="m-0">{data}</p>,
        ...this.getColumnSearchProps('classification'),
      },
      {
        key: 'published',
        title: 'Published',
        dataIndex: 'published',
        sorter: (a, b) => a !== b,
        render: data => <p className="m-0">{data ? <Tag color="success">Published</Tag> : <Tag color="warning">Unpublished</Tag>}</p>,
      },
      {
        key: 'updated_at',
        title: 'Last Edit',
        dataIndex: 'updated_at',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'extra',
        dataIndex: 'extra',
        render: id => <Link to={`/projects/edit/${id}`}><Button icon={<EditOutlined />}>Edit</Button></Link>
      },
    ]

    return <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Projects</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          extra={[
            <Link key='1' to="/projects/new"><Button onClick={this.onProjectCreate} type="primary" icon={<PlusOutlined />}>Add Project</Button></Link>
          ]}
          style={{padding: 0}}
          title="Projects"
        />
        <Divider />

        <Table pagination={{showSizeChanger: true}} loading={this.state._isLoading} columns={tablecols} dataSource={this.getTableData()} />
      </Card>
    </div>
  }
}