import React, {PureComponent, useState} from 'react';
import { Layout, Menu, Button } from 'antd';
import {Link, useLocation} from 'react-router-dom';
import {startsWith, split} from 'ramda';
import {
  LogoutOutlined,
  ReadOutlined,
  TagsOutlined,
  SyncOutlined,
  SettingOutlined,
  GlobalOutlined,
  PartitionOutlined,
  HighlightOutlined,
  TeamOutlined,
  PhoneOutlined
} from '@ant-design/icons';

const { Header, Footer, Sider, Content } = Layout;
const { SubMenu } = Menu;

export default function PageLayout(props) {

  const [collapsed, setCollapsed] = useState(false);
  const {pathname} = useLocation();
  const {children} = props;
  const selectedKeys = () => {
    if(pathname === '/'){
      return ['dashboard'];
    }

    if(startsWith('/pages/native/homepage', pathname)){
      return ['pages.native.homepage'];
    }

    if(startsWith('/pages/native/family', pathname)){
      return ['pages.native.family'];
    }

    if(startsWith('/pages/native/projects', pathname)){
      return ['pages.native.projects'];
    }

    if(startsWith('/pages/native/contact', pathname)){
      return ['pages.native.contact'];
    }
    
    if(startsWith('/pages/native/privacypolicy', pathname)){
      return ['pages.native.privacypolicy'];
    }

    if(startsWith('/pages/native/tc', pathname)){
      return ['pages.native.tc'];
    }

    if(startsWith('/pages/native/winecatalog', pathname)){
      return ['pages.native.winecatalog'];
    }

    if(startsWith('/pages/native/foodcatalog', pathname)){
      return ['pages.native.foodcatalog'];
    }

    if(startsWith('/pages/native/blog', pathname)){
      return ['pages.native.blog'];
    }
  
    let path = split('/', pathname.substr(1));
  
    if(path.length > 1){
      path = [path[0], `${path[0]}.${path[1]}`]
    }    
  
    return path;
  }

  return <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={()=>setCollapsed(!collapsed)}>
        <div className={`admin-logo ${collapsed && `collapsed`}`}><img src="/img/admin-logo.svg" /></div>
        <Menu theme="dark" defaultSelectedKeys={['dashboard']} selectedKeys={selectedKeys()} mode="inline">
          <Menu.Item key="dashboard">
            <Link to="/">
              <PhoneOutlined />
              <span>Leads</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="projects">
            <Link to="/projects">
              <GlobalOutlined />
              <span>Projects</span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="attributes"
            title={
              <span>
                <PartitionOutlined />
                <span>Attributes</span>
              </span>
            }
          >
            <Menu.Item key="attributes.appellations"><Link to="/attributes/appellations"><span>Appellations</span></Link></Menu.Item>
            <Menu.Item key="attributes.classifications"><Link to="/attributes/classifications"><span>Classifications</span></Link></Menu.Item>
            <Menu.Item key="attributes.countries"><Link to="/attributes/countries"><span>Countries</span></Link></Menu.Item>
            <Menu.Item key="attributes.regions"><Link to="/attributes/regions"><span>Regions</span></Link></Menu.Item>
            <Menu.Item key="attributes.producers"><Link to="/attributes/producers"><span>Producers</span></Link></Menu.Item>
            <Menu.Item key="attributes.grapes"><Link to="/attributes/grapes"><span>Wine Grapes</span></Link></Menu.Item>
            <Menu.Item key="attributes.colours"><Link to="/attributes/colours"><span>Wine Colours</span></Link></Menu.Item>
            <Menu.Item key="attributes.types"><Link to="/attributes/types"><span>Wine Types</span></Link></Menu.Item>
            <Menu.Item key="attributes.concepts"><Link to="/attributes/concepts"><span>Wine Concepts</span></Link></Menu.Item>
            <Menu.Item key="attributes.custom"><Link to="/attributes/custom"><span>Custom Attributes</span></Link></Menu.Item>
          </SubMenu>
          <Menu.Item key="wines">
            <Link to="/wines">
              <TagsOutlined />
              <span>Wines</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="food">
            <Link to="/food">
              <TagsOutlined />
              <span>Food</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="recommendations">
            <Link to="/recommendations">
              <SyncOutlined />
              <span>Recommendations</span>
            </Link>
          </Menu.Item>
          <SubMenu
            key="blog"
            title={
              <span>
                <HighlightOutlined />
                <span>Blog</span>
              </span>
            }
          >
            <Menu.Item key="blog.articles"><Link to="/blog/articles"><span>Articles</span></Link></Menu.Item>
            <Menu.Item key="blog.categories"><Link to="/blog/categories"><span>Categories</span></Link></Menu.Item>
          </SubMenu>
          <SubMenu
            key="pages"
            title={
              <span>
                <ReadOutlined />
                <span>Pages</span>
              </span>
            }
          >
            <Menu.Item key="pages.native.homepage"><Link to="/pages/native/homepage"><span>Homepage</span></Link></Menu.Item>
            <Menu.Item key="pages.native.projects"><Link to="/pages/native/projects"><span>Projects</span></Link></Menu.Item>
            <Menu.Item key="pages.native.family"><Link to="/pages/native/family"><span>Family WxF</span></Link></Menu.Item>
            <Menu.Item key="pages.native.blog"><Link to="/pages/native/blog"><span>Blog</span></Link></Menu.Item>
            <Menu.Item key="pages.native.contact"><Link to="/pages/native/contact"><span>Contact</span></Link></Menu.Item>
            <Menu.Item key="pages.native.winecatalog"><Link to="/pages/native/winecatalog"><span>Wine Catalog</span></Link></Menu.Item>
            <Menu.Item key="pages.native.foodcatalog"><Link to="/pages/native/foodcatalog"><span>Food Catalog</span></Link></Menu.Item>
            <Menu.Item key="pages.native.privacypolicy"><Link to="/pages/native/privacypolicy"><span>Privacy Policy</span></Link></Menu.Item>
            <Menu.Item key="pages.native.tc"><Link to="/pages/native/tc"><span>Terms &amp; Conditions</span></Link></Menu.Item>
          </SubMenu>
          <Menu.Item key="users">
            <Link to="/users">
              <TeamOutlined />
              <span>Admin Users</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="settings">
            <Link style={{color: 'inherit'}} to="/settings">
              <SettingOutlined />
              <span>General Settings</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{ padding: 0 }}>
        <Header className="site-layout-background d-flex justify-content-end align-items-center" style={{ padding: 0 }}>
          <Button onClick={props.onSignOut} type="danger" style={{marginRight: 16}} icon={<LogoutOutlined />}>Sign Out</Button>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          {children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>Wine x Food | Admin Panel</Footer>
      </Layout>
    </Layout>
}