import React, { PureComponent, Suspense } from 'react';
import {Users} from '@api';
import {BrowserRouter as Router, Switch, Route, Redirect, useLocation} from 'react-router-dom';
import Layout from '@global/Layout';
import LoginLayout from '@global/LoginLayout';
import Leads from '@pages/leads/Leads';
import EditLead from '@pages/leads/EditLead';
import Settings from '@pages/settings/Settings';
import Projects from '@pages/projects/Projects';
import NewProject from '@pages/projects/NewProject';
import EditProject from '@pages/projects/EditProject';
import Wines from '@pages/wines/Wines';
import NewWine from '@pages/wines/NewWine';
import EditWine from '@pages/wines/EditWine';
import Food from '@pages/food/Food';
import NewFood from '@pages/food/NewFood';
import EditFood from '@pages/food/EditFood';
import UsersPage from '@pages/users/Users';
import EditUser from '@pages/users/EditUser';
import NewUser from '@pages/users/NewUser';
import Recommendations from '@pages/recommendations/Recommendations';

//Native Pages
import HomePage from '@pages/native_pages/HomePage';
import ProjectsPage from '@pages/native_pages/ProjectsPage';
import FamilyPage from '@pages/native_pages/FamilyPage';
import ContactPage from '@pages/native_pages/ContactPage';
import WineCatalogPage from '@pages/native_pages/WineCatalog';
import FoodCatalogPage from '@pages/native_pages/FoodCatalog';
import PrivacyPolicyPage from '@pages/native_pages/PrivacyPolicy';
import TCPage from '@pages/native_pages/TCPage';
import BlogPage from '@pages/native_pages/Blog';

//Attributes
import CustomAttributes from '@pages/attributes/custom/Custom';
import NewCustomAttribute from '@pages/attributes/custom/NewCustom';
import EditCustomAttribute from '@pages/attributes/custom/EditCustom';
import Appellations from '@pages/attributes/appellations/Appellations';
import NewAppellation from '@pages/attributes/appellations/NewAppellation';
import EditAppellation from '@pages/attributes/appellations/EditAppellation';
import Classifications from '@pages/attributes/classifications/Classifications';
import NewClassification from '@pages/attributes/classifications/NewClassification';
import EditClassification from '@pages/attributes/classifications/EditClassification';
import Countries from '@pages/attributes/countries/Countries';
import NewCountry from '@pages/attributes/countries/NewCountry';
import EditCountry from '@pages/attributes/countries/EditCountry';
import Regions from '@pages/attributes/regions/Regions';
import NewRegion from '@pages/attributes/regions/NewRegion';
import EditRegion from '@pages/attributes/regions/EditRegion';
import Producers from '@pages/attributes/producers/Producers';
import NewProducer from '@pages/attributes/producers/NewProducer';
import EditProducer from '@pages/attributes/producers/EditProducer';
import Grapes from '@pages/attributes/grapes/Grapes';
import NewGrape from '@pages/attributes/grapes/NewGrape';
import EditGrape from '@pages/attributes/grapes/EditGrape';
import Colours from '@pages/attributes/colours/Colours';
import NewColour from '@pages/attributes/colours/NewColour';
import EditColour from '@pages/attributes/colours/EditColour';
import Types from '@pages/attributes/types/Types';
import NewType from '@pages/attributes/types/NewType';
import EditType from '@pages/attributes/types/EditType';
import BlogCategories from '@pages/blog/categories/Categories';
import NewBlogCategory from '@pages/blog/categories/NewCategory';
import EditBlogCategory from '@pages/blog/categories/EditCategory';
import Articles from '@pages/blog/articles/Articles';
import NewArticle from '@pages/blog/articles/NewArticle';
import EditArticle from '@pages/blog/articles/EditArticle';
import Concepts from '@pages/attributes/concepts/Concepts';
import NewConcept from '@pages/attributes/concepts/NewConcept';
import EditConcept from '@pages/attributes/concepts/EditConcept';

import '@global/styles/styles.scss';
import 'antd/dist/antd.css';
import {LoadingOutlined} from '@ant-design/icons'; 

const ProtectedRoute = ({component: Component, ...rest}) => {
  return <Route
    {...rest}
    render={props => {
      if(rest.signed_in && Component){
        return <Component {...props} />
      }
      return <Redirect to="/signin" />}
    }
  />
}

class App extends PureComponent {

  state = {
    signed_in: false,
    is_loading: true,
    user: {},
  }

  componentWillMount(){
    this.checkAuth();
  }

  // componentWillUpdate(){
  //   this.checkAuth();
  // }

  async checkAuth() {
    const { signed_in } = this.state;

    const userCheck = await Users.isAdminSignedIn();

    if (!userCheck.signed_in || userCheck.error) {
      
      this.setState({
        is_loading: false,
      });

      if (signed_in) {
        this.onUserSignOut();
        return;
      }
      
      return;
    }

    if (!signed_in) {
      this.onUserSignIn({user: userCheck.user});
      return;
    }

  }

  onUserSignOut = async () => {

    await Users.signOut();

    this.setState({
      signed_in: false,
      is_loading: false,
      user: {}
    })
  }

  onUserSignIn = ({user}) => {
    this.setState({
      signed_in: true,
      is_loading: false,
      user
    })
  }

  render(){

    const {is_loading, signed_in, user} = this.state;

    return <Router>
      <Switch>
        {is_loading ? <div>Loading</div> : <Suspense fallback={<div>Loading...</div>}>
          
          <Route
            exact={true}
            path="/signin"
            render={props=><LoginLayout signed_in={signed_in} user={user} onUserSignOut={this.onUserSignOut} onUserSignIn={this.onUserSignIn} {...props} {...this.props} />}
          />

          {signed_in ? <Layout onSignOut={this.onUserSignOut}>

            <ProtectedRoute exact={true} signed_in={signed_in} path="/recommendations" component={Recommendations} />
            
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/homepage" component={HomePage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/projects" component={ProjectsPage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/family" component={FamilyPage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/contact" component={ContactPage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/winecatalog" component={WineCatalogPage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/foodcatalog" component={FoodCatalogPage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/privacypolicy" component={PrivacyPolicyPage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/tc" component={TCPage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/pages/native/blog" component={BlogPage} />

            <ProtectedRoute exact={true} signed_in={signed_in} path="/users" component={UsersPage} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/users/new" component={NewUser} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/users/edit/:id" component={EditUser} />
            
            <ProtectedRoute exact={true} signed_in={signed_in} path="/settings" component={Settings} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/projects" component={Projects} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/projects/new" component={NewProject} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/projects/edit/:id" component={EditProject} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/wines" component={Wines} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/wines/new" component={NewWine} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/wines/edit/:id" component={EditWine} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/concepts" component={Concepts} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/concepts/new" component={NewConcept} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/concepts/edit/:id" component={EditConcept} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/appellations" component={Appellations} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/appellations/new" component={NewAppellation} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/appellations/edit/:id" component={EditAppellation} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/classifications" component={Classifications} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/classifications/new" component={NewClassification} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/classifications/edit/:id" component={EditClassification} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/countries" component={Countries} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/countries/edit/:id" component={EditCountry} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/countries/new" component={NewCountry} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/regions" component={Regions} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/regions/edit/:id" component={EditRegion} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/regions/new" component={NewRegion} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/producers" component={Producers} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/producers/edit/:id" component={EditProducer} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/producers/new" component={NewProducer} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/grapes" component={Grapes} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/grapes/edit/:id" component={EditGrape} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/grapes/new" component={NewGrape} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/colours" component={Colours} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/colours/edit/:id" component={EditColour} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/colours/new" component={NewColour} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/types" component={Types} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/types/edit/:id" component={EditType} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/types/new" component={NewType} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/custom" component={CustomAttributes} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/custom/edit/:id" component={EditCustomAttribute} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/attributes/custom/new" component={NewCustomAttribute} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/blog/categories" component={BlogCategories} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/blog/categories/new" component={NewBlogCategory} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/blog/categories/edit/:id" component={EditBlogCategory} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/blog/articles" component={Articles} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/blog/articles/new" component={NewArticle} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/blog/articles/edit/:id" component={EditArticle} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/food" component={Food} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/food/new" component={NewFood} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/food/edit/:id" component={EditFood} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/" component={Leads} />
            <ProtectedRoute exact={true} signed_in={signed_in} path="/leads/edit/:id" component={EditLead} />

          </Layout> : <ProtectedRoute/>}

        </Suspense>}
      </Switch>
    </Router>
  }
}

export default App;
