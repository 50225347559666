import React, {PureComponent} from 'react';
import moment from 'moment';
import {PageHeader, Card, Button, message, Divider, Breadcrumb, Table, Tag, Input, Space} from 'antd';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';
import {PlusOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import {Wines} from '@api';
import configs from '@global/configs';
import {pathOr} from 'ramda';

export default class WinesPage extends PureComponent{

  state = {
    _isLoading: true,
    wines: [],
    searchText: '',
    searchedColumn: '',
  }

  async componentDidMount(){
    const _res = await Wines.getWines();
    if(_res.error){
      message.error(_res.message || "Can't complete request due to server error. Try later.", 3)
      return;
    }

    this.setState({
      _isLoading: false,
      wines: _res.data.wines
    })
  }

  getTableData = () => {
    const {wines} = this.state;

    return wines.map((data, i) => {
      return {
        key: data.id,
        id: data.id,
        image: data.preview_image ? <img className="list-logo-img" src={`${configs.api}/static/img/${data.preview_image}`} /> : <img className="list-logo-img" src="/img/no-image.png" />,
        name: data.name || 'N/a',
        producer: pathOr('N/a', ['producer.name'], data),
        appellation: pathOr('N/a', ['appellation.name', 'en'], data),
        project: pathOr('N/a', ['project.name'], data),
        published: data.is_published,
        updated_at: moment(data.updated_at).utc().format('MM/DD/YYYY HH:mm'),
        extra: data.id
      }
    })
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  render(){

    const tablecols = [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        render: data => <p className="m-0"><strong>{data}</strong></p>
      },
      {
        key: 'image',
        title: 'Image',
        dataIndex: 'image',
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: data => <strong className="m-0">{data}</strong>,
        ...this.getColumnSearchProps('name'),
      },
      {
        key: 'producer',
        title: 'Producer',
        dataIndex: 'producer',
        sorter: (a, b) => { return a.producer.localeCompare(b.producer)},
        render: data => <p className="m-0">{data}</p>,
        ...this.getColumnSearchProps('producer'),
      },
      {
        key: 'appellation',
        title: 'Appelation',
        dataIndex: 'appellation',
        sorter: (a, b) => { return a.appellation.localeCompare(b.appellation)},
        render: data => <p className="m-0">{data}</p>,
        ...this.getColumnSearchProps('appellation'),
      },
      {
        key: 'project',
        title: 'Project',
        dataIndex: 'project',
        sorter: (a, b) => { return a.project.localeCompare(b.project)},
        render: data => <p className="m-0">{data}</p>,
        ...this.getColumnSearchProps('project'),
      },
      {
        key: 'published',
        title: 'Published',
        dataIndex: 'published',
        sorter: (a, b) => a !== b,
        render: data => <p className="m-0">{data ? <Tag color="success">Published</Tag> : <Tag color="warning">Unpublished</Tag>}</p>
      },
      {
        key: 'updated_at',
        title: 'Last Edit',
        dataIndex: 'updated_at',
        sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'extra',
        dataIndex: 'extra',
        render: id => <Link to={`/wines/edit/${id}`}><Button icon={<EditOutlined />}>Edit</Button></Link>
      },
    ]

    return <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Wines</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          extra={[
            <Link key='1' to="/wines/new"><Button type="primary" icon={<PlusOutlined />}>Add Wine</Button></Link>
          ]}
          style={{padding: 0}}
          title="Wines"
        />
        <Divider />

        <Table pagination={{showSizeChanger: true}} loading={this.state._isLoading} columns={tablecols} dataSource={this.getTableData()} />
      </Card>
    </div>
  }
}