import configs from '@global/configs';
import axios from 'axios';

const API = configs.api;

export const getClassifications = async () => await axios.get(`${API}/classifications`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const getRegions = async () => await axios.get(`${API}/countries/regions`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const getCountry = async ({id}) => await axios.get(`${API}/countries/id/${id}`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const createClassification = async (data = {}) => await axios.post(`${API}/classifications/create`, data, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {

    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
    
  });