import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Select, Form, Input, message, Tabs, Divider, Upload, Switch} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, InboxOutlined} from '@ant-design/icons';
import {NativePages, StaticAPI, Attributes} from '@api';
import {filter, pathOr, propEq} from 'ramda';
import configs from '@global/configs';

const {TabPane} = Tabs;
const {TextArea} = Input;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;

export default class FamilyPage extends PureComponent{

  state = {
    isLoading: true,
    page: {
      id: null,
      page_id: null,
      content: null,
      title: null,
      meta: null
    },
  }

  async componentDidMount(){
    const _res = await NativePages.getPage({page_id: 3});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    _res.data.page.imgblocks = pathOr([], ['content', 'imgblocks'], _res.data.page).map((block, i)=>{
      return {
        ...block,
        img: block.img ? {
          file: {
            uid: `uid-imgblock-${i}`,
            url: `${configs.api}/static/img/${block.img}`,
            name: 'Block Image',
            status: 'done',
            response: {
              data: {
                filename: block.img
              }
            }
          }
        } : null
      }
    })

    _res.data.page.people = pathOr([], ['content', 'people'], _res.data.page).map((block, i)=>{
      return {
        ...block,
        img: block.img ? {
          file: {
            uid: `uid-people-${i}`,
            url: `${configs.api}/static/img/${block.img}`,
            name: 'Person Picture',
            status: 'done',
            response: {
              data: {
                filename: block.img
              }
            }
          }
        } : null
      }
    })

    _res.data.page.concept = pathOr([], ['content', 'concept'], _res.data.page).map((block, i)=>{
      return {
        ...block,
        img: block.img ? {
          file: {
            uid: `uid-concept-${i}`,
            url: `${configs.api}/static/img/${block.img}`,
            name: 'Concept Block Image',
            status: 'done',
            response: {
              data: {
                filename: block.img
              }
            }
          }
        } : null
      }
    })

    this.setState({
      isLoading: false,
      page: _res.data.page,
    });

  }

  onSave = async values => {

    //SAFE IMAGE PICK
    values.content.imgblocks = pathOr([], ['content', 'imgblocks'], values).map(block => {
      return {
        ...block,
        img: pathOr(null, ['img', 'file', 'response', 'data', 'filename'], block) || block.img
      }
    })

    values.content.people = pathOr([], ['content', 'people'], values).map(block => {
      return {
        ...block,
        img: pathOr(null, ['img', 'file', 'response', 'data', 'filename'], block) || block.img
      }
    })

    values.content.concept = pathOr([], ['content', 'concept'], values).map(block => {
      return {
        ...block,
        img: pathOr(null, ['img', 'file', 'response', 'data', 'filename'], block) || block.img
      }
    })

    const _res = await NativePages.updatePage({
      id: this.state.page.id,
      ...values,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  render(){

    const {isLoading, page} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="page"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={page}
          >

            <PageHeader
              title="Edit Family WXF Page"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Tabs defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Page title"
                  name={['title', 'en']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Enter page title" />
                </Form.Item>
                <Form.Item
                  label="Page subtitle"
                  name={['content','subtitle', 'en']}
                >
                  <TextArea rows={2} placeholder="Enter page subtitle" />
                </Form.Item>
                <Form.Item
                  label="Concept section title"
                  name={['content', 'concept_title', 'en']}
                  rules={[{ required: true, message: 'Please input concept title' }]}
                >
                  <Input placeholder="Enter concept title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Page title"
                  name={['title', 'ru']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Enter page title" />
                </Form.Item>
                <Form.Item
                  label="Page subtitle"
                  name={['content','subtitle', 'ru']}
                >
                  <TextArea rows={2} placeholder="Enter page subtitle" />
                </Form.Item>
                <Form.Item
                  label="Concept section title"
                  name={['content', 'concept_title', 'ru']}
                  rules={[{ required: true, message: 'Please input concept title' }]}
                >
                  <Input placeholder="Enter concept title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Page title"
                  name={['title', 'es']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Enter page title" />
                </Form.Item>
                <Form.Item
                  label="Page subtitle"
                  name={['content','subtitle', 'es']}
                >
                  <TextArea rows={2} placeholder="Enter page subtitle" />
                </Form.Item>
                <Form.Item
                  label="Concept section title"
                  name={['content', 'concept_title', 'es']}
                  rules={[{ required: true, message: 'Please input concept title' }]}
                >
                  <Input placeholder="Enter concept title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Page title"
                  name={['title', 'cn']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Enter page title" />
                </Form.Item>
                <Form.Item
                  label="Page subtitle"
                  name={['content','subtitle', 'cn']}
                >
                  <TextArea rows={2} placeholder="Enter page subtitle" />
                </Form.Item>
                <Form.Item
                  label="Concept section title"
                  name={['content', 'concept_title', 'cn']}
                  rules={[{ required: true, message: 'Please input concept title' }]}
                >
                  <Input placeholder="Enter concept title" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <h3>People Info</h3>
            <p>Edit information about people at WXF Family Page</p>

            <Form.List name={['content', 'people']}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, i) => (

                      <div className="row">
                        <div className="col-12">
                          <Button style={{marginTop: 20, marginBottom: 20}} type="dashed" onClick={() => {
                            remove(field.name);
                          }} icon={<DeleteOutlined />}>Remove Person</Button>
                        </div>
                        <div className="col-12 col-lg-8">
                          <Tabs key={`people-tab-${i}`} defaultActiveKey="en">

                            <TabPane forceRender tab="English (primary)" key="en">
                              <Form.Item
                                {...field}
                                key={`people-title-en-${i}`}
                                label="Person's name"
                                name={[field.name, 'title', 'en']}
                              >
                                <Input placeholder="Enter name of the person" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`people-text-en-${i}`}
                                label="Person info"
                                name={[field.name, 'text', 'en']}
                              >
                                <TextArea rows={4} placeholder="Enter person info" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Russian" key="ru">
                              <Form.Item
                                {...field}
                                key={`people-title-ru-${i}`}
                                label="Person's name"
                                name={[field.name, 'title', 'ru']}
                              >
                                <Input placeholder="EEnter name of the person" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`people-text-ru-${i}`}
                                label="Person info"
                                name={[field.name, 'text', 'ru']}
                              >
                                <TextArea rows={4} placeholder="Enter person info" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Spanish" key="es">
                              <Form.Item
                                {...field}
                                key={`people-title-es-${i}`}
                                label="Person's name"
                                name={[field.name, 'title', 'es']}
                              >
                                <Input placeholder="EEnter name of the person" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`people-text-es-${i}`}
                                label="Person info"
                                name={[field.name, 'text', 'es']}
                              >
                                <TextArea rows={4} placeholder="Enter person info" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Chinese" key="cn">
                              <Form.Item
                                {...field}
                                key={`people-title-cn-${i}`}
                                label="Person's name"
                                name={[field.name, 'title', 'cn']}
                              >
                                <Input placeholder="EEnter name of the person" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`people-text-cn-${i}`}
                                label="Person info"
                                name={[field.name, 'text', 'cn']}
                              >
                                <TextArea rows={4} placeholder="Enter person info" />
                              </Form.Item>
                            </TabPane>

                          </Tabs>
                        </div>
                        <div className="col-12 col-lg-4">

                          <h4 style={{marginBottom: 20}}>Person Picture</h4>

                          <Form.Item key={`people-img-${i}`} {...field} name={[field.name, 'img']} noStyle>
                            <Upload
                              listType="text"
                              withCredentials
                              accept="image/*"
                              name="img"
                              action={`${configs.api}/static/img/upload`}
                            >
                              <Button>
                                <UploadOutlined /> Upload New Picture
                              </Button>
                            </Upload>
                          </Form.Item>

                        </div>
                      </div>

                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Person
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <Divider />
            
            <h3>Image/Text Blocks</h3>
            
            <Form.List name={['content', 'imgblocks']}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, i) => (

                      <div className="row">
                        <div className="col-12">
                          <Button style={{marginTop: 20, marginBottom: 20}} type="dashed" onClick={() => {
                            remove(field.name);
                          }} icon={<DeleteOutlined />}>Remove Block</Button>
                        </div>
                        <div className="col-12 col-lg-8">
                          <Tabs key={`imgblock-tab-${i}`} defaultActiveKey="en">

                            <TabPane forceRender tab="English (primary)" key="en">
                              <Form.Item
                                {...field}
                                key={`imgblock-title-en-${i}`}
                                label="Block title"
                                name={[field.name, 'title', 'en']}
                              >
                                <Input placeholder="Enter page title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`imgblock-text-en-${i}`}
                                label="Block text"
                                name={[field.name, 'text', 'en']}
                              >
                                <TextArea rows={4} placeholder="Enter block text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Russian" key="ru">
                              <Form.Item
                                {...field}
                                key={`imgblock-title-ru-${i}`}
                                label="Block title"
                                name={[field.name, 'title', 'ru']}
                              >
                                <Input placeholder="Enter page title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`imgblock-text-ru-${i}`}
                                label="Block text"
                                name={[field.name, 'text', 'ru']}
                              >
                                <TextArea rows={4} placeholder="Enter block text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Spanish" key="es">
                              <Form.Item
                                {...field}
                                key={`imgblock-title-es-${i}`}
                                label="Block title"
                                name={[field.name, 'title', 'es']}
                              >
                                <Input placeholder="Enter page title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`imgblock-text-es-${i}`}
                                label="Block text"
                                name={[field.name, 'text', 'es']}
                              >
                                <TextArea rows={4} placeholder="Enter block text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Chinese" key="cn">
                              <Form.Item
                                {...field}
                                key={`imgblock-title-cn-${i}`}
                                label="Block title"
                                name={[field.name, 'title', 'cn']}
                              >
                                <Input placeholder="Enter page title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`imgblock-text-cn-${i}`}
                                label="Block text"
                                name={[field.name, 'text', 'cn']}
                              >
                                <TextArea rows={4} placeholder="Enter block text" />
                              </Form.Item>
                            </TabPane>

                          </Tabs>
                        </div>
                        <div className="col-12 col-lg-4">

                          <h4 style={{marginBottom: 20}}>Block Image</h4>

                          <Form.Item key={`imgblock-img-${i}`} {...field} name={[field.name, 'img']} noStyle>
                            <Upload
                              listType="text"
                              withCredentials
                              accept="image/*"
                              name="img"
                              action={`${configs.api}/static/img/upload`}
                            >
                              <Button>
                                <UploadOutlined /> Upload New Image
                              </Button>
                            </Upload>
                          </Form.Item>

                        </div>
                      </div>

                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Block
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <Divider />

            <h3>Concept of Company Blocks</h3>
            <p>Edit company concept blocks</p>

            <Form.List name={['content', 'concept']}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, i) => (

                      <div className="row">
                        <div className="col-12">
                          <Button style={{marginTop: 20, marginBottom: 20}} type="dashed" onClick={() => {
                            remove(field.name);
                          }} icon={<DeleteOutlined />}>Remove Concept Block</Button>
                        </div>
                        <div className="col-12 col-lg-8">
                          <Tabs key={`concept-tab-${i}`} defaultActiveKey="en">

                            <TabPane forceRender tab="English (primary)" key="en">
                              <Form.Item
                                {...field}
                                key={`concept-title-en-${i}`}
                                label="Concept name"
                                name={[field.name, 'title', 'en']}
                              >
                                <Input placeholder="Enter name of the concept" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Russian" key="ru">
                              <Form.Item
                                {...field}
                                key={`concept-title-ru-${i}`}
                                label="Concept name"
                                name={[field.name, 'title', 'ru']}
                              >
                                <Input placeholder="Enter name of the concept" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Spanish" key="es">
                              <Form.Item
                                {...field}
                                key={`concept-title-es-${i}`}
                                label="Concept name"
                                name={[field.name, 'title', 'es']}
                              >
                                <Input placeholder="Enter name of the concept" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Chinese" key="cn">
                              <Form.Item
                                {...field}
                                key={`concept-title-cn-${i}`}
                                label="Concept name"
                                name={[field.name, 'title', 'cn']}
                              >
                                <Input placeholder="Enter name of the concept" />
                              </Form.Item>
                            </TabPane>

                          </Tabs>
                        </div>
                        <div className="col-12 col-lg-4">

                          <h4 style={{marginBottom: 20}}>Concept Picture</h4>

                          <Form.Item key={`concept-img-${i}`} {...field} name={[field.name, 'img']} noStyle>
                            <Upload
                              listType="text"
                              withCredentials
                              accept="image/*"
                              name="img"
                              action={`${configs.api}/static/img/upload`}
                            >
                              <Button>
                                <UploadOutlined /> Upload New Image
                              </Button>
                            </Upload>
                          </Form.Item>

                        </div>
                      </div>

                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Concept Block
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <Divider />

            <h3>Meta Information</h3>
            <p style={{marginBottom: 20}}>Meta information is for Search Engines (title, description, tags). Please note, not all search engines will necesseraly use this data directly.</p>

            <Tabs key={`meta-tabs`} defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'en', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'en', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'en', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'ru', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'ru', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'ru', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'es', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'es', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'es', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'cn', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'cn', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'cn', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <Form.Item
              label="Show Contact Form"
              name={['content', 'show_form']}
            >
              <Switch defaultChecked={pathOr(false, ['content', 'show_form'], page)} />
            </Form.Item>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>
              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}