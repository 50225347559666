import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';

export default function Loading({title = "Loading…",  subtitle = "Please wait."}){

  return <div className="page-loading-container">
    <h2>{title}</h2>
    <p>{subtitle}</p>
    <LoadingOutlined style={{fontSize: '30px', margin: 20}} />
  </div>

}