import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Tabs, Select, Form, Input, message, Popconfirm, Divider, Switch} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined} from '@ant-design/icons';
import {Attributes} from '@api';

const {Option} = Select;
const {TabPane} = Tabs;

export default class EditAttribute extends PureComponent{

  state = {
    isLoading: true,
    attribute: {
      id: null,
      name: {},
      type: null,
      updated_at: null,
    },
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    const _res = await Attributes.getAttribute({id});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      attribute: _res.data.attribute
    });

  }

  onSave = async values => {

    if(values.type !== 'text'){

      if(!values.defaults || values.defaults.length === 0){
        message.error('Attribute should have at least 1 option.');
        return;
      }

    }

    const _res = await Attributes.updateAttribute({
      id: this.state.attribute.id,
      ...values,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Attributes.deleteAttribute({id: this.state.attribute.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/attributes/custom";
    });

  }

  onTypeChange = e => {
    this.setState({
      attribute: {
        ...this.state.attribute,
        type: e
      }
    });

    return;
  }

  render(){

    const {isLoading, attribute} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="attribute"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={attribute}
          >

            <PageHeader
              title="Edit Attribute"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Form.Item
              label="Show in Wine/Food Details page (when available)"
              name='sortable'
            >
              <Switch defaultChecked={attribute.sortable || false} />
            </Form.Item>

            <Tabs defaultActiveKey="en" >
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Name"
                  name={['name', 'en']}
                  rules={[{ required: true, message: 'Please input attribute name' }]}
                >
                  <Input placeholder="Enter attribute name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Name"
                  name={['name', 'ru']}
                >
                  <Input placeholder="Enter attribute name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Name"
                  name={['name', 'es']}
                >
                  <Input placeholder="Enter attribute name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Name"
                  name={['name', 'cn']}
                >
                  <Input placeholder="Enter attribute name" />
                </Form.Item>
              </TabPane>
            </Tabs>
            
            <Divider />

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Attribute Type"
                  name='type'
                  rules={[{ required: true, message: 'Please select type' }]}
                >
                  <Select onChange={this.onTypeChange}>
                    <Option value="text">Plain Text</Option>
                    <Option value="select">Select (Predefined)</Option>
                    <Option value="tags">Select + Type (Predefined)</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Default Options (multiple)"
                  name='defaults'
                >
                  <Select placeholder="Start typing and press 'Enter'" mode="tags" disabled={this.state.attribute.type === 'text'} />
                </Form.Item>
              </div>
            </div>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}