import React, {PureComponent} from 'react';
import moment from 'moment';
import {PageHeader, Card, Button, message, Divider, Breadcrumb, Table, Tag} from 'antd';
import {Link} from 'react-router-dom';
import {PlusOutlined, EditOutlined} from '@ant-design/icons';
import {Attributes} from '@api';
import configs from '@global/configs';
import {pathOr} from 'ramda';

export default class ConceptsPage extends PureComponent{

  state = {
    _isLoading: true,
    concepts: []
  }

  async componentDidMount(){
    const _res = await Attributes.getConcepts();
    if(_res.error){
      message.error(_res.message || "Can't complete request due to server error. Try later.", 3)
      return;
    }

    this.setState({
      _isLoading: false,
      concepts: _res.data.concepts
    })
  }

  getTableData = () => {
    const {concepts} = this.state;

    return concepts.map((data, i) => {
      return {
        key: data.id,
        id: data.id,
        logo: data.logo,
        name: pathOr('N/a', ['name', 'en'], data),
        updated_at: moment(data.updated_at).utc().format('MM/DD/YYYY HH:mm'),
        extra: data.id
      }
    })
  }

  render(){

    const tablecols = [
      {
        key: 'logo',
        dataIndex: 'logo',
        width: 30,
        render: data => data && <img src={`${configs.api}/static/img/${data}`} width={30} />
      },
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: data => <strong className="m-0">{data}</strong>
      },
      {
        key: 'updated_at',
        title: 'Last Edit',
        dataIndex: 'updated_at',
        sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'extra',
        dataIndex: 'extra',
        align: 'right',
        render: id => <Link to={`/attributes/concepts/edit/${id}`}><Button icon={<EditOutlined />}>Edit</Button></Link>
      },
    ]

    return <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Wine Concepts</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          extra={[
            <Link key='1' to="/attributes/concepts/new"><Button type="primary" icon={<PlusOutlined />}>Add Wine Concept</Button></Link>
          ]}
          style={{padding: 0}}
          title="Wine Concepts"
        />
        <Divider />

        <Table pagination={{showSizeChanger: true}} loading={this.state._isLoading} columns={tablecols} dataSource={this.getTableData()} />
      </Card>
    </div>
  }
}