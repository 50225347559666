const mode = process.env.NODE_ENV || 'development'
const config = {
  development: {
    is_production: false,
    api: 'http://127.0.0.1:3002'
  },
  production: {
    is_production: true,
    api: 'https://api.winexfood.com'
  },
}

export default config[mode];