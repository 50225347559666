import configs from '@global/configs';
import axios from 'axios';

const API = configs.api;

export const getProducers = async () => await axios.get(`${API}/producers`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const getProducer = async ({id}) => await axios.get(`${API}/producers/id/${id}`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const createProducer = async (data = {}) => await axios.post(`${API}/producers/create`, data, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {

    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
    
  });

export const updateProducer = async (data = {}) => await axios.post(`${API}/producers/update/${data.id}`, data, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {

    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
    
  });