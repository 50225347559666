import configs from "@global/configs";
import axios from "axios";

const API = configs.api;

export const getArticles = async () =>
  await axios
    .get(`${API}/blog/articles`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getCategories = async () =>
  await axios
    .get(`${API}/blog/categories`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getArticle = async ({ id }) =>
  await axios
    .get(`${API}/blog/articles/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getCategory = async ({ id }) =>
  await axios
    .get(`${API}/blog/categories/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createArticle = async (data = {}) =>
  await axios
    .post(`${API}/blog/articles/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createCategory = async (data = {}) =>
  await axios
    .post(`${API}/blog/categories/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateArticle = async (data = {}) =>
  await axios
    .post(`${API}/blog/articles/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateCategory = async (data = {}) =>
  await axios
    .post(`${API}/blog/categories/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteCategory = async (data = {}) =>
  await axios
    .delete(`${API}/blog/categories/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteArticle = async (data = {}) =>
  await axios
    .delete(`${API}/blog/articles/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });
