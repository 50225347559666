import React, {Component} from 'react';
import {Layout, Tabs, Card, Input, Divider, Form, Button, message, Tag, Switch} from 'antd';
import {SaveOutlined, FacebookOutlined, InstagramOutlined, CloudDownloadOutlined} from '@ant-design/icons';
import {Users, Keys} from '@api';
import validator from 'validator';
import {pathOr, find, propEq} from 'ramda';

const {TabPane} = Tabs;
const { Content } = Layout;
const { TextArea } = Input;

export default class Settings extends Component{

  state = {
    _isSubmittingUser: false,
    _isSubmittingPassword: false,
    user: null,
    contacts: null,
    apikeys: null,
  }

  async componentDidMount(){
    const _res = await Users.getCurrentUser();
    const _resKeys = await Keys.getKeys();

    if(_res.error || _resKeys.error){
      message.error('Server error, please try again or contact administrator');
    }
  
    this.setState({
      user: _res.data.user,
      contacts: find(propEq('type', 1),_resKeys.data.keys),
      apikeys: find(propEq('type', 2),_resKeys.data.keys),
    });
  }

  onPasswordUpdate = async values => {

    if(values.new_password !== values.new_password_repeat){
      message.error("New passwords don't match", 2);
      return;
    }

    const _res = await Users.updatePassword({
      id: this.state.user.id,
      ...values
    });

    if(_res.error){
      message.error(_res.message || "Server error, please try again", 1);
      return;
    }

    message.success("Successfully updated");
  }

  onKeysSave = async (values, key) => {

    const _res = await Keys.updateKey({
      id: key === 1 ? this.state.contacts.id : this.state.apikeys.id,
      ...values,
    })

    if(_res.error){
      message.error("Server error, please try again", 1);
      return;
    }

    message.success("Successfully updated");

  }

  onUserDetailsSave = async values => {

    if(values.email){
      values.email = values.email.toLowerCase();
    }

    const _res = await Users.updateUser({
      id: this.state.user.id,
      ...values,
    })

    if(_res.error){
      message.error(_res.message || "Server error, please try again", 1);
      return;
    }

    message.success("Successfully updated");
  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  render(){

    const {apikeys, contacts, user} = this.state;

    return <div className="row ">

        <div className="col-12 col-xl-6">
          <Card loading={!user} style={{marginBottom: 20}} title="Your details">
            <Form
              name="user"
              layout="vertical"
              onFinish={this.onUserDetailsSave}
              onFinishFailed={this.onValidationError}            
              scrollToFirstError
              initialValues={user}
            >
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="last_name"
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input type="email" placeholder="Enter email" />
              </Form.Item>
              <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>
            </Form>
          </Card>
          <Card style={{marginBottom: 20}} title="Change password">
            <Form
              name="password"
              layout="vertical"
              onFinish={this.onPasswordUpdate}
              onFinishFailed={this.onValidationError}            
              scrollToFirstError
            >

              <Form.Item
                label="Current Password"
                name="current_password"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input.Password placeholder="Enter your current password" />
              </Form.Item>
              
              <Form.Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  },
                  {
                    min: 6,
                    message: 'At least 6 letters'
                  },
                ]}
              >
                <Input.Password placeholder="Enter your new password" />
              </Form.Item>
              
              <Form.Item
                label="Repeat New Password"
                name="new_password_repeat"
                rules={[
                  {
                    required: true,
                    message: 'This field is required'
                  },
                  {
                    min: 6,
                    message: 'At least 6 letters'
                  },
                ]}
              >
                <Input.Password placeholder="Repeat your old password" />
              </Form.Item>

              <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>Update Password</Button>
            </Form>
          </Card>
          <Card loading={!contacts} style={{marginBottom: 20}} title="Business details">
            <Form
              name="contacts"
              layout="vertical"
              onFinish={values => this.onKeysSave(values, 1)}
              onFinishFailed={this.onValidationError}            
              scrollToFirstError
              initialValues={contacts}
            >

              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Item
                    label="Show Food Page/Catalog on website?"
                    name={['content', 'showfood']}
                  >
                    <Switch defaultChecked={pathOr(false, ['content', 'showfood'], contacts)} />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item
                    label="Show Blog on website?"
                    name={['content', 'showblog']}
                  >
                    <Switch defaultChecked={pathOr(false, ['content', 'showblog'], contacts)} />
                  </Form.Item>
                </div>
              </div>

              <h4>Conset Modal Message</h4>

              <Tabs defaultActiveKey="en" >
                <TabPane forceRender tab="English (primary)" key="en">
                  <Form.Item
                    label="Conset title"
                    name={['content', 'conset', 'title', 'en']}
                    rules={[{ required: true, message: 'Please input conset window title' }]}
                  >
                    <Input placeholder="Enter window title" />
                  </Form.Item>
                  <Form.Item
                    label="Conset message"
                    name={['content', 'conset', 'message', 'en']}
                    rules={[{ required: true, message: 'Please input conset message' }]}
                  >
                    <Input.TextArea rows={3} placeholder="Enter conset message" />
                  </Form.Item>
                  <Form.Item
                    label="Button confirm title"
                    name={['content', 'conset', 'button_title', 'en']}
                    rules={[{ required: true, message: 'Please input confirm title' }]}
                  >
                    <Input placeholder="Enter button confirm title" />
                  </Form.Item>
                </TabPane>
                <TabPane forceRender tab="Russian" key="ru">
                  <Form.Item
                    label="Conset title"
                    name={['content', 'conset', 'title', 'ru']}
                    rules={[{ required: true, message: 'Please input conset window title' }]}
                  >
                    <Input placeholder="Enter window title" />
                  </Form.Item>
                  <Form.Item
                    label="Conset message"
                    name={['content', 'conset', 'message', 'ru']}
                    rules={[{ required: true, message: 'Please input conset message' }]}
                  >
                    <Input.TextArea rows={3} placeholder="Enter conset message" />
                  </Form.Item>
                  <Form.Item
                    label="Button confirm title"
                    name={['content', 'conset', 'button_title', 'ru']}
                    rules={[{ required: true, message: 'Please input confirm title' }]}
                  >
                    <Input placeholder="Enter button confirm title" />
                  </Form.Item>
                </TabPane>
                <TabPane forceRender tab="Spanish" key="es">
                  <Form.Item
                    label="Conset title"
                    name={['content', 'conset', 'title', 'es']}
                    rules={[{ required: true, message: 'Please input conset window title' }]}
                  >
                    <Input placeholder="Enter window title" />
                  </Form.Item>
                  <Form.Item
                    label="Conset message"
                    name={['content', 'conset', 'message', 'es']}
                    rules={[{ required: true, message: 'Please input conset message' }]}
                  >
                    <Input.TextArea rows={3} placeholder="Enter conset message" />
                  </Form.Item>
                  <Form.Item
                    label="Button confirm title"
                    name={['content', 'conset', 'button_title', 'es']}
                    rules={[{ required: true, message: 'Please input confirm title' }]}
                  >
                    <Input placeholder="Enter button confirm title" />
                  </Form.Item>
                </TabPane>
                <TabPane forceRender tab="Chinese" key="cn">
                  <Form.Item
                    label="Conset title"
                    name={['content', 'conset', 'title', 'cn']}
                    rules={[{ required: true, message: 'Please input conset window title' }]}
                  >
                    <Input placeholder="Enter window title" />
                  </Form.Item>
                  <Form.Item
                    label="Conset message"
                    name={['content', 'conset', 'message', 'cn']}
                    rules={[{ required: true, message: 'Please input conset message' }]}
                  >
                    <Input.TextArea rows={3} placeholder="Enter conset message" />
                  </Form.Item>
                  <Form.Item
                    label="Button confirm title"
                    name={['content', 'conset', 'button_title', 'cn']}
                    rules={[{ required: true, message: 'Please input confirm title' }]}
                  >
                    <Input placeholder="Enter button confirm title" />
                  </Form.Item>
                </TabPane>
              </Tabs>

              <Divider />

              <Form.Item
                label="Phone"
                name={['content', 'phone']}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Enter phone" />
              </Form.Item>
              <Form.Item
                label="Email"
                name={['content', 'email']}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input type="email" placeholder="Enter email" />
              </Form.Item>
              <Form.Item
                label="Address"
                name={['content', 'address']}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <TextArea rows={2} placeholder="Enter address" />
              </Form.Item>

              <Divider />

              <Form.Item
                label="Facebook URL"
                name={['content', 'facebook']}
              >
                <Input addonBefore={<FacebookOutlined />} type="url" placeholder="Enter URL" />
              </Form.Item>

              <Form.Item
                label="Instagram URL"
                name={['content', 'instagram']}
              >
                <Input addonBefore={<InstagramOutlined />} type="url" placeholder="Enter URL" />
              </Form.Item>
              
              <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>
            </Form>
          </Card>
        </div>

        <div className="col-12 col-xl-6">
          <Card loading={!apikeys} style={{marginBottom: 20}} title="API Keys">
            <Tag style={{marginBottom: 20}} color="red">Danger Zone - Do not change unless you know what you are doing!</Tag>
            <p>Please note: Analytics Scripts and Tags can be changed in <a target="_blank" href="https://analytics.google.com">Google Analytics</a>, <a target="_blank" href="https://metrika.yandex.ru">Yandex Metrika</a> and <a target="_blank" href="https://tagmanager.google.com/">Google Tag Manager</a> websites respectively.</p>

            <Form
              name="apikeys"
              layout="vertical"
              onFinish={values => this.onKeysSave(values, 2)}
              onFinishFailed={this.onValidationError}            
              scrollToFirstError
              initialValues={apikeys}
            >
              <Form.Item
                label="Bitrix API Key"
                name={['content', 'bitrix', 'key']}
              >
                <Input placeholder="Enter key value" />
              </Form.Item>
              
              <Form.Item
                label="Google Maps API Key"
                name={['content', 'google', 'mapskey']}
              >
                <Input placeholder="Enter key value" />
              </Form.Item>

              <Form.Item
                label="Google Analytics ID"
                name={['content', 'google', 'analytics_id']}
              >
                <Input placeholder="Enter key value" />
              </Form.Item>

              <Form.Item
                label="Google reCAPTCHA Site Key"
                name={['content', 'google', 'recaptcha', 'sitekey']}
              >
                <Input placeholder="Enter key value" />
              </Form.Item>

              <Form.Item
                label="Google reCAPTCHA Secret Key"
                name={['content', 'google', 'recaptcha', 'secretkey']}
              >
                <Input placeholder="Enter key value" />
              </Form.Item>
              <Button htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>
            </Form>
          </Card>
          {/* <Card style={{marginBottom: 20}} title="Export Wine/Projects Data">
            <p>Export wine data to Excel file</p>

            <Button icon={<CloudDownloadOutlined />}>Export Data</Button>
          </Card> */}
        </div>


      </div>

  }
}