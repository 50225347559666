import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Select, Form, Input, message, Tabs, Divider, Upload, Switch} from 'antd';
import Loading from '@components/Loading/Loading'
import EditorJs from 'react-editor-js';
import EditorHeader from '@editorjs/header';
import EditorImage from '@editorjs/image';
import EditorList from '@editorjs/list';
import EditorParagraph from '@editorjs/paragraph';
import EditorRaw from '@editorjs/raw';
import {SaveOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, InboxOutlined} from '@ant-design/icons';
import {NativePages, StaticAPI, Attributes} from '@api';
import {filter, pathOr, propEq} from 'ramda';
import configs from '@global/configs';

const {TabPane} = Tabs;
const {TextArea} = Input;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;
const EDITOR_JS_TOOLS = {
  header: {
    class: EditorHeader,
    inlineToolbar: true
  },
  image: {
    class: EditorImage,
    inlineToolbar: true,
    config: {
      uploader: {
        async uploadByFile(file){
          let formData = new FormData();
          formData.append('img', file)
          const _res = await StaticAPI.uploadArticleImage(formData);
          return _res;
        }
      }
    }
  },
  list: {
    class: EditorList,
    inlineToolbar: true
  },
  paragraph: {
    class: EditorParagraph,
    inlineToolbar: true
  },
  raw: {
    class: EditorRaw,
    inlineToolbar: true
  },
}

export default class TCPage extends PureComponent{

  state = {
    isLoading: true,
    page: {
      id: null,
      page_id: null,
      content: null,
      title: null,
      meta: null
    },
  }

  async componentDidMount(){
    this.editorInstanceEN = null;
    this.editorInstanceRU = null;
    this.editorInstanceES = null;
    this.editorInstanceCN = null;

    const _res = await NativePages.getPage({page_id: 8});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      page: _res.data.page,
    });

  }

  parseEditorHTML = blocks => {

    let _html = "";

    blocks.map(b=>{

      switch (b.type) {
        case 'raw':
          _html += b.data.html;
          break;

        case 'paragraph':
          _html += `<p>${b.data.text}</p>`;
          break;

        case 'image':
          _html += `<figure class="blog-article-image ${b.data.withBorder && "with-border"} ${b.data.stretched && "stretched"} ${b.data.withBackground && "with-background"}"><img data-zoomable src="${b.data.file.url}" data-src="${b.data.file.filename}" alt="${b.data.caption || "WXF"}" />${b.data.caption && `<figcaption>${b.data.caption}</figcaption>`}</figure>`
          break;

        case 'header':
          _html += `<h${b.data.level}>${b.data.text}</h${b.data.level}>`
          break;

        case 'list':
          _html += `${b.data.style === 'unordered' ? '<ul>' : '<ol>'}${b.data.items.map(i => `<li><p>${i}</p></li>`).join('')}${b.data.style === 'unordered' ? '</ul>' : '</ol>'}`;

          break;
      
        default:
          break;
      }

    });

    return _html;

  }

  onSave = async values => {

    const editorBlocksEN = await this.editorInstanceEN.save();
    const editorBlocksRU = await this.editorInstanceRU.save();
    const editorBlocksES = await this.editorInstanceES.save();
    const editorBlocksCN = await this.editorInstanceCN.save();

    let editorHTMLEN = this.parseEditorHTML(editorBlocksEN.blocks || []);
    let editorHTMLRU = this.parseEditorHTML(editorBlocksRU.blocks || []);
    let editorHTMLES = this.parseEditorHTML(editorBlocksES.blocks || []);
    let editorHTMLCN = this.parseEditorHTML(editorBlocksCN.blocks || []);

    values.content.blocks = {
      en: editorBlocksEN || [],
      ru: editorBlocksRU || [],
      es: editorBlocksES || [],
      cn: editorBlocksCN || [],
    }

    values.content.html = {
      en: editorHTMLEN || "",
      ru: editorHTMLRU || "",
      es: editorHTMLES || "",
      cn: editorHTMLCN || "",
    }

    const _res = await NativePages.updatePage({
      id: this.state.page.id,
      ...values,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  render(){

    const {isLoading, page} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="page"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={page}
          >

            <PageHeader
              title="Edit Terms and Conditions Page"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Tabs defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Title"
                  name={['title', 'en']}
                  rules={[{ required: true, message: 'Please input title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>

                <Divider />
                <h4>Policy Content</h4>

                <EditorJs holder="editor-en" key="editor-en" data={pathOr([], ['content', 'blocks', 'en'], page)} instanceRef={instance => this.editorInstanceEN = instance} minHeight={80} placeholder="Start writing something ✍️" tools={EDITOR_JS_TOOLS}>
                  <div className="editor-inpage" id="editor-en" />
                </EditorJs>

              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Title"
                  name={['title', 'ru']}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>

                <Divider />
                <h4>Policy Content</h4>
                
                <EditorJs holder="editor-ru" key="editor-ru" data={pathOr([], ['content', 'blocks', 'ru'], page)} instanceRef={instance => this.editorInstanceRU = instance} minHeight={80} placeholder="Start writing something ✍️" tools={EDITOR_JS_TOOLS}>
                  <div className="editor-inpage" id="editor-ru" />
                </EditorJs>
                
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Title"
                  name={['title', 'es']}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>

                <Divider />
                <h4>Policy Content</h4>
                
                <EditorJs holder="editor-es" key="editor-es" data={pathOr([], ['content', 'blocks', 'es'], page)} instanceRef={instance => this.editorInstanceES = instance} minHeight={80} placeholder="Start writing something ✍️" tools={EDITOR_JS_TOOLS}>
                  <div className="editor-inpage" id="editor-es" />
                </EditorJs>

              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Title"
                  name={['title', 'cn']}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>

                <Divider />
                <h4>Policy Content</h4>
                
                <EditorJs holder="editor-cn" key="editor-cn" data={pathOr([], ['content', 'blocks', 'cn'], page)} instanceRef={instance => this.editorInstanceCN = instance} minHeight={80} placeholder="Start writing something ✍️" tools={EDITOR_JS_TOOLS}>
                  <div className="editor-inpage" id="editor-cn" />
                </EditorJs>

              </TabPane>
            </Tabs>

            <Divider />

            <h3>Meta Information</h3>
            <p style={{marginBottom: 20}}>Meta information is for Search Engines (title, description, tags). Please note, not all search engines will necesseraly use this data directly.</p>

            <Tabs key={`meta-tabs`} defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'en', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'en', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'en', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'ru', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'ru', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'ru', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'es', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'es', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'es', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'cn', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'cn', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'cn', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <Form.Item
              label="Show Contact Form"
              name={['content', 'show_form']}
            >
              <Switch disabled defaultChecked={pathOr(false, ['content', 'show_form'], page)} />
            </Form.Item>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>
              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}