import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Tabs, Select, Form, Input, message, Popconfirm, Divider, Tag} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined, LinkOutlined} from '@ant-design/icons';
import {Leads} from '@api';

const {Option} = Select;

export default class EditLeadPage extends PureComponent{

  state = {
    isLoading: true,
    lead: null,
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    const _res = await Leads.getLead({id});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      lead: _res.data.lead
    });

  }

  onSave = async values => {
    const _res = await Leads.updateLead({
      id: this.state.lead.id,
      ...values,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Leads.deleteLead({id: this.state.lead.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/";
    });

  }

  render(){

    const {isLoading, lead} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card isLoading={!lead}>

          <Form
            name="lead"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={lead}
          >

            <PageHeader
              title="Edit Lead"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Please select lead status' }]}
            >
              <Select>
                <Option value="new">New</Option>
                <Option value="processing">Processing</Option>
                <Option value="completed">Completed</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input visitor name' }]}
            >
              <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="tel"
            >
              <Input placeholder="Phone No." />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
            >
              <Input type="email" placeholder="Email" />
            </Form.Item>

            <Divider />

            <h4>Message</h4>
            <p>{lead.message || 'N/a'}</p>

            <Divider />

            <h4>Lead Source</h4>
            <Tag color="processing">
              <a href={lead.source} target="_blank">{lead.source}</a>
            </Tag>

            <Divider />

            <h4>Bitrix Status</h4>
            <p>It's not recommended to change this field manually. All leads are automatically sent to Bitrix and have Bitrix ID. If for some reason Bitrix ID is not filled here, you can manually input Bitrix Deal ID (numeric value).</p>

            <Form.Item
              label="Bitrix ID"
              name="bitrix_id"
            >
              <Input type="number" placeholder="Bitrix ID" />
            </Form.Item>

            {lead.bitrix_id && <a href={`https://winexfood.bitrix24.kz/crm/deal/details/${lead.bitrix_id}/`}>Go to Bitrix24 Deal <LinkOutlined /></a>}

            <Divider />
            
            {lead.bitrix_id ? <div><h4>Comments</h4><p>Edit comments in Bitrix. If you want to add comments from here, unlink this deal from Bitrix, by removing Bitrix ID above.</p><p dangerouslySetInnerHTML={{__html: lead.comments }} /></div> : <Form.Item
              label="Comments (for internal use)"
              name="comments"
            >
              <Input.TextArea rows={6} placeholder="Internal comments" />
            </Form.Item>}

            <Form.Item
              label={<div>Customer Response <Tag color="red">Visible to Customer</Tag></div>}
              name="response"
            >
              <Input.TextArea rows={4} placeholder="Warning! This message will be shown to Customer" />
            </Form.Item>

            <Divider />


            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}