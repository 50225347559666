import React, {PureComponent} from 'react';
import {Redirect} from 'react-router-dom';
import moment from 'moment';
import { Users } from '@api';
import { Layout, Menu, Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const { Header, Content, Footer } = Layout;

export default class LoginLayout extends PureComponent{

  state = {
    isLoading: false,
    isResettingPass: false,
  }

  onSignIn = async (values, t) => {

    const {isResettingPass} = this.state;
    const {signed_in, user, onUserSignOut, onUserSignIn} = this.props;

    if(values.email){
      values.email = values.email.toLowerCase();
    }

    this.setState({isLoading: true});

    if(isResettingPass){
      const _res = await Users.resetPassword(values);

      this.setState({
        isLoading: false
      });

      if(_res.error){
        message.error("Unable to reset password", 3);
        return;
      }

      message.success("Email with the password reset instructions sent", 3);
      return;
    }

    const _resSignIn = await Users.doSignIn(values);

    this.setState({isLoading: false});

    if(_resSignIn.error){
      onUserSignOut();
      message.error('Incorrect user/password. Try again or reset your password with Forgot Password.', 3);
      return;
    }

    onUserSignIn({user: _resSignIn.user});
    return;
    
  };

  switchMode = e => {
    e.preventDefault();

    this.setState({
      isResettingPass: !this.state.isResettingPass
    })
  }

  render(){

    const {isLoading, isResettingPass} = this.state;
    const {signed_in} = this.props;

    return signed_in ? <Redirect to="/" /> : <Layout className="layout">
      <Header>
        <div className="admin-logo centered"><img src="/img/admin-logo.svg" /></div>
      </Header>
      <Content className="container-admin">
        <div className="row">
          <div className="col-12">
            <Form
              name="normal_login"
              size="large"
              className="admin-login-form"
              initialValues={{ remember: true }}
              onFinish={this.onSignIn}
            >
              <h1>Sign In</h1>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!'
                  },
                ]}
              >
                <Input
                  disabled={isLoading}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>
              {!isResettingPass && <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  disabled={isLoading}
                />
              </Form.Item>}
              <Form.Item>
                <Button type="primary" htmlType="submit" className="admin-login-form-button" loading={isLoading}>{isResettingPass ? 'Reset Password' : 'Sign In'}</Button>
              </Form.Item>
              <Form.Item>
                <a className="admin-login-form-forgot" onClick={this.switchMode}>
                  {isResettingPass ? 'Back to Sign In' : 'Forgot Password'}
                </a>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Wine x Food &copy; {moment().format('YYYY')} Designed by <a href="https://theblur.kz">The Blur</a></Footer>
    </Layout>
  }

}