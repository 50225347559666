import React, {PureComponent} from 'react';
import moment from 'moment';
import {PageHeader, Card, Button, message, Divider, Breadcrumb, Table, Tag, Input, Space} from 'antd';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';
import {PlusOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import {Blog} from '@api';
import configs from '@global/configs';
import {pathOr} from 'ramda';

export default class ArticlesPage extends PureComponent{

  state = {
    _isLoading: true,
    articles: [],
    categories: [],
    searchText: '',
    searchedColumn: '',
  }

  async componentDidMount(){
    const _resArticles = await Blog.getArticles();
    const _resCategories = await Blog.getCategories();

    if(_resArticles.error || _resCategories.error){
      message.error(_resArticles.message || _resCategories.message || "Can't complete request due to server error. Try later.", 3)
      return;
    }

    this.setState({
      _isLoading: false,
      articles: _resArticles.data.articles,
      categories: _resCategories.data.categories,
    })
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  getTableData = () => {
    const {articles} = this.state;

    return articles.map((data, i) => {

      let lang = "English";

      switch (data.lang) {
        case 2:
          lang = "Russian";
          break;
        case 3:
          lang = "Spanish";
          break;
        case 4:
          lang = "Chinese";
          break;
      
        default:
          break;
      }

      return {
        key: data.id,
        id: data.id,
        preview_image: pathOr(null, ['preview_image'], data),
        name: pathOr('N/a', ['name'], data),
        is_published: data.is_published,
        is_main: data.is_main || false,
        lang,
        category: pathOr("No Category", ['category.name', 'en'], data),
        updated_at: moment(data.updated_at).utc().format('MM/DD/YYYY HH:mm'),
        extra: data.id
      }
    })
  }

  render(){

    const tablecols = [
      {
        key: 'preview_image',
        dataIndex: 'preview_image',
        render: data => data ? <img className="list-logo-img" src={`${configs.api}/static/img/${data}`} /> : <img className="list-logo-img" src="/img/no-image.png" />
      },
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: data => <strong className="m-0">{data}</strong>,
        ...this.getColumnSearchProps('name'),
      },
      {
        key: 'category',
        title: 'Category',
        dataIndex: 'category',
        sorter: (a, b) => { return a.category.localeCompare(b.category)},
        render: data => data && <Tag color="blue">{data}</Tag>,
        ...this.getColumnSearchProps('category'),
      },
      {
        key: 'lang',
        title: 'Language',
        dataIndex: 'lang',
        sorter: (a, b) => { return a.lang.localeCompare(b.lang)},
        render: data => data && <Tag color="blue">{data}</Tag>,
        ...this.getColumnSearchProps('language'),
      },
      {
        key: 'is_main',
        title: 'Main Article',
        dataIndex: 'is_main',
        sorter: (a, b) => { return a.lang.localeCompare(b.lang)},
        render: data => data && <Tag color="green">Yes</Tag>
      },
      {
        key: 'is_published',
        title: 'Published',
        dataIndex: 'is_published',
        sorter: (a, b) => a !== b,
        render: data => <p className="m-0">{data ? <Tag color="success">Published</Tag> : <Tag color="warning">Unpublished</Tag>}</p>
      },
      {
        key: 'updated_at',
        title: 'Last Edit',
        dataIndex: 'updated_at',
        sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'extra',
        dataIndex: 'extra',
        align: 'right',
        render: id => <Link to={`/blog/articles/edit/${id}`}><Button icon={<EditOutlined />}>Edit</Button></Link>
      },
    ]

    return <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Blog Articles</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          extra={[
            <Link key='1' to="/blog/articles/new"><Button type="primary" icon={<PlusOutlined />}>Add Blog Article</Button></Link>
          ]}
          style={{padding: 0}}
          title="Articles"
        />
        <Divider />

        <Table pagination={{showSizeChanger: true}} loading={this.state._isLoading} columns={tablecols} dataSource={this.getTableData()} />
      </Card>
    </div>
  }
}