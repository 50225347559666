import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Select, Form, Input, message, Tabs, Divider, Upload, Switch} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, InboxOutlined} from '@ant-design/icons';
import {NativePages, StaticAPI, Attributes} from '@api';
import {filter, pathOr, propEq} from 'ramda';
import configs from '@global/configs';

const {TabPane} = Tabs;
const {TextArea} = Input;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;

export default class BlogPage extends PureComponent{

  state = {
    isLoading: true,
    page: null,
  }

  async componentDidMount(){
    const _res = await NativePages.getPage({page_id: 9});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      page: _res.data.page,
    });

  }

  onSave = async values => {

    const _res = await NativePages.updatePage({
      id: this.state.page.id,
      ...values,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  render(){

    const {isLoading, page} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card loading={!page}>

          <Form
            name="page"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={page}
          >

            <PageHeader
              title="Edit Blog Page"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Tabs defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Title"
                  name={['title', 'en']}
                  rules={[{ required: true, message: 'Please input title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>
                <Form.Item
                  label="Page info"
                  name={['content', 'info', 'en']}
                >
                  <TextArea rows={3} placeholder="Enter page info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
              <Form.Item
                  label="Title"
                  name={['title', 'ru']}
                  rules={[{ required: true, message: 'Please input title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>
                <Form.Item
                  label="Page info"
                  name={['content', 'info', 'ru']}
                >
                  <TextArea rows={3} placeholder="Enter page info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Title"
                  name={['title', 'es']}
                  rules={[{ required: true, message: 'Please input title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>
                <Form.Item
                  label="Page info"
                  name={['content', 'info', 'es']}
                >
                  <TextArea rows={3} placeholder="Enter page info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Title"
                  name={['title', 'cn']}
                  rules={[{ required: true, message: 'Please input title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>
                <Form.Item
                  label="Page info"
                  name={['content', 'info', 'cn']}
                >
                  <TextArea rows={3} placeholder="Enter page info" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <h3>Meta Information</h3>
            <p style={{marginBottom: 20}}>Meta information is for Search Engines (title, description, tags). Please note, not all search engines will necesseraly use this data directly.</p>

            <Tabs key={`meta-tabs`} defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'en', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'en', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'en', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'ru', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'ru', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'ru', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'es', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'es', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'es', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'cn', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'cn', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'cn', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>
              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}