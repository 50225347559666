import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Select, Form, Input, message, Popconfirm, Divider, Switch, Upload} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined, InboxOutlined} from '@ant-design/icons';
import EditorJs from 'react-editor-js';
import EditorHeader from '@editorjs/header';
import EditorEmbed from '@editorjs/embed';
import EditorImage from '@editorjs/image';
import EditorList from '@editorjs/list';
import EditorParagraph from '@editorjs/paragraph';
import EditorRaw from '@editorjs/raw';
import slugify from 'slugify';
import {Blog, StaticAPI} from '@api';
import configs from '@global/configs';
import {pathOr} from 'ramda';

const {TextArea} = Input;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;
const EDITOR_JS_TOOLS = {
  header: {
    class: EditorHeader,
    inlineToolbar: true
  },
  image: {
    class: EditorImage,
    inlineToolbar: true,
    config: {
      uploader: {
        async uploadByFile(file){
          let formData = new FormData();
          formData.append('img', file)
          const _res = await StaticAPI.uploadArticleImage(formData);
          return _res;
        }
      }
    }
  },
  list: {
    class: EditorList,
    inlineToolbar: true
  },
  paragraph: {
    class: EditorParagraph,
    inlineToolbar: true
  },
  raw: {
    class: EditorRaw,
    inlineToolbar: true
  },
  embed: {
    class: EditorEmbed,
    config: {
      services: {
        youtube: true,
        vimeo: true,
      }
    },
    inlineToolbar: true
  },
}

export default class EditClassification extends PureComponent{

  state = {
    isLoading: true,
    article: {
      id: null,
      name: null,
      slug: null,
      blocks: [],
      html: null,
      preview_text: null,
      preview_image: null,
      lang: null,
      is_published: false,
      is_main: false,
    },
    categories: [],
    previewImageList: [],
  }

  onSingleImageUpload = (info, field) => {
    const { status, response } = info.file;

    this.setState({
      [field]: [{
        ...info.file,
        url: `${configs.api}/static/img/${pathOr('', ['response', 'data', 'filename'], info.file)}`
      }]
    })

    if (status === 'done') {

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }

    if(status ==='removed'){
      this.setState({
        [field]: [],
      })
    }
  }

  onSingleImageRemove = async (e, field) => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      this.setState({
        [field]: []
      })

      message.success("File deleted", 1)
    }

  }

  async componentDidMount(){
    this.editorInstance = null;
    const id = this.props.match.params.id;
    const _res = await Blog.getArticle({id});
    const _resCategories = await Blog.getCategories();

    if(_res.error || _resCategories.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    const previewImageList = pathOr(null, ['data', 'article', 'preview_image'], _res) && [{
      uid: `uid-image-1`,
      url: `${configs.api}/static/img/${_res.data.article.preview_image}`,
      name: 'Preview Image',
      status: 'done',
      response: {
        data: {
          filename: _res.data.article.preview_image
        }
      }
    }] || [];

    this.setState({
      isLoading: false,
      article: _res.data.article,
      categories: _resCategories.data.categories,
      previewImageList
    });

  }

  parseEditorHTML = blocks => {
    let _html = "";

    blocks.map(b=>{

      switch (b.type) {
        case 'embed':
          _html += `<div class="article-embed"><iframe width="560" height="315" src="${b.data.embed}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
          break;

        case 'raw':
          _html += b.data.html;
          break;

        case 'paragraph':
          _html += `<p>${b.data.text}</p>`;
          break;

        case 'image':
          _html += `<figure class="blog-article-image ${b.data.withBorder && "with-border"} ${b.data.stretched && "stretched"} ${b.data.withBackground && "with-background"}"><img data-zoomable src="${b.data.file.url}" data-src="${b.data.file.filename}" alt="${b.data.caption || "WXF"}" />${b.data.caption && `<figcaption>${b.data.caption}</figcaption>`}</figure>`
          break;

        case 'header':
          _html += `<h${b.data.level}>${b.data.text}</h${b.data.level}>`
          break;

        case 'list':
          _html += `${b.data.style === 'unordered' ? '<ul>' : '<ol>'}${b.data.items.map(i => `<li><p>${i}</p></li>`).join('')}${b.data.style === 'unordered' ? '</ul>' : '</ol>'}`;

          break;
      
        default:
          break;
      }

    });

    return _html;

  }

  onSave = async values => {

    const editorBlocks = await this.editorInstance.save();
    let editorHTML = this.parseEditorHTML(editorBlocks.blocks || []);

    if(values.category_id === values.second_category_id){
      
      if(values.category_id !== 0 && values.second_category_id !== 0){
        return message.error("Primary and secondary categories can't match,");
      }

    }

    const preview_image = pathOr('', ['previewImageList', 0, 'response', 'data', 'filename'], this.state);

    const slug = slugify(pathOr('', ['name'], values)).toLowerCase();

    const _res = await Blog.updateArticle({
      id: this.state.article.id,
      ...values,
      preview_image,
      slug,
      blocks: editorBlocks || [],
      html: editorHTML || "",
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;
  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  getCategoryOptions = () => {
    const {categories} = this.state;

    if(categories.length === 0){
      return [];
    }

    return categories.map(c =>{
      return <Option label={pathOr('N/a', ['name', 'en'], c)} key={c.id} value={c.id}>{pathOr('N/a', ['name', 'en'], c)}</Option>
    })
  }

  onDelete = async () => {
    this.setState({
      isLoading: true
    });

    const deleted = await Blog.deleteArticle({id: this.state.article.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/blog/articles";
    });
  }

  render(){

    const {isLoading, article, previewImageList} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="article"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={article}
          >

            <PageHeader
              title="Edit Article"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Form.Item
              label="Article title"
              name="name"
              rules={[{ required: true, message: 'Please input article title' }]}
            >
              <Input placeholder="Enter article title" />
            </Form.Item>

            <Form.Item
              label="Article preview text"
              name="preview_text"
              rules={[{ required: true, message: 'Please input article preview text' }]}
            >
              <TextArea rows={2} placeholder="Enter article preview text" />
            </Form.Item>

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Primary category"
                  name="category_id"
                  rules={[{ required: true, message: 'Please choose primary category' }]}
                >
                  <Select showSearch optionFilterProp="label" style={{ width: '100%' }} placeholder="Choose one">
                    <Option key={0} value={0}>No category</Option>
                    {this.getCategoryOptions()}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Additional category"
                  name="second_category_id"
                >
                  <Select showSearch optionFilterProp="label" allowClear style={{ width: '100%' }} placeholder="Choose one">
                    <Option key={0} value={0}>No category</Option>
                    {this.getCategoryOptions()}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <Form.Item
              label="Slug"
              name="slug"
            >
              <Input disabled placeholder="Generated automatically" />
            </Form.Item>

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Article language"
                  name="lang"
                  rules={[{ required: true, message: 'Please select article language' }]}
                >
                  <Select placeholder="Select article language">
                    <Option value={1}>English</Option>
                    <Option value={2}>Russian</Option>
                    <Option value={3}>Spanish</Option>
                    <Option value={4}>Chinese</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-12 col-lg-3">
                <Form.Item
                  label="Main article"
                  name="is_main"
                >
                  <Switch defaultChecked={article.is_main} />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-3">
                <Form.Item
                  label="Published"
                  name="is_published"
                >
                  <Switch defaultChecked={article.is_published} />
                </Form.Item>
              </div>
            </div>

            <Divider />

            <h3>Article image</h3>

            <Form.Item name="preview_image" noStyle>
              <Dragger
                listType="picture"
                fileList={previewImageList}
                onChange={e => this.onSingleImageUpload(e, 'previewImageList')}
                onRemove={e => this.onSingleImageRemove(e, 'previewImageList')}
                withCredentials
                multiple={false}
                accept="image/*"
                name="img"
                action={`${configs.api}/static/img/upload`}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Upload Image</p>
              </Dragger>
            </Form.Item>

            <Divider />

            <h3>Article Content</h3>
            <p>Edit article content here</p>

            <EditorJs data={article.blocks} instanceRef={instance => this.editorInstance = instance} minHeight={80} placeholder="Start writing something ✍️" tools={EDITOR_JS_TOOLS} />

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}