import React, {PureComponent} from 'react';
import moment from 'moment';
import {PageHeader, Card, Button, message, Divider, Breadcrumb, Table, Tag} from 'antd';
import {Link} from 'react-router-dom';
import {PlusOutlined, EditOutlined} from '@ant-design/icons';
import {Users} from '@api';
import configs from '@global/configs';
import {pathOr} from 'ramda';

export default class UsersPage extends PureComponent{

  state = {
    _isLoading: true,
    users: []
  }

  async componentDidMount(){
    const _res = await Users.getUsers();
    if(_res.error){
      message.error(_res.message || "Can't complete request due to server error. Try later.", 3)
      return;
    }

    this.setState({
      _isLoading: false,
      users: _res.data.users
    })
  }

  getTableData = () => {
    const {users} = this.state;

    return users.map((data, i) => {

      return {
        key: data.id,
        id: data.id,
        name: `${pathOr('', ['first_name'], data)} ${pathOr('', ['last_name'], data)}`,
        email: pathOr('N/a', ['email'], data),
        is_blocked: data.is_blocked,
        updated_at: moment(data.updated_at).utc().format('MM/DD/YYYY HH:mm'),
        extra: data.id
      }
    })
  }

  render(){

    const tablecols = [
      {
        key: 'name',
        title: 'Full Name',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: data => <strong className="m-0">{data}</strong>
      },
      {
        key: 'email',
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => { return a.email.localeCompare(b.email)},
        render: data => data && <Tag className="m-0">{data}</Tag>
      },
      {
        key: 'is_blocked',
        title: 'Status',
        dataIndex: 'is_blocked',
        sorter: (a, b) => { return a.is_blocked.localeCompare(b.is_blocked)},
        render: is_blocked => is_blocked ? <Tag color="orange" className="m-0">Blocked</Tag> : <Tag color="blue" className="m-0">Active</Tag>
      },
      {
        key: 'updated_at',
        title: 'Last Edit',
        dataIndex: 'updated_at',
        sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'extra',
        dataIndex: 'extra',
        align: 'right',
        render: id => <Link to={`/users/edit/${id}`}><Button icon={<EditOutlined />}>Edit</Button></Link>
      },
    ]

    return <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Admin Users</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <PageHeader
          extra={[
            <Link key='1' to="/users/new"><Button type="primary" icon={<PlusOutlined />}>Add User</Button></Link>
          ]}
          style={{padding: 0}}
          title="Admin Users"
        />
        <Divider />

        <Table pagination={{showSizeChanger: true}} loading={this.state._isLoading} columns={tablecols} dataSource={this.getTableData()} />
      </Card>
    </div>
  }
}