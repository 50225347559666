import configs from "@global/configs";
import axios from "axios";

const API = configs.api;

export const getAttributes = async () =>
  await axios
    .get(`${API}/attributes`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getAttribute = async ({ id }) =>
  await axios
    .get(`${API}/attributes/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createAttribute = async (data = {}) =>
  await axios
    .post(`${API}/attributes/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateAttribute = async (data = {}) =>
  await axios
    .post(`${API}/attributes/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteAttribute = async (data = {}) =>
  await axios
    .delete(`${API}/attributes/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getWineAttributes = async (data) =>
  await axios
    .get(`${API}/attributes/wines`, { params: data }, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getFoodAttributes = async (data) =>
  await axios
    .get(`${API}/attributes/food`, { params: data }, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

/**** APPELLATIONS *****/
export const getAppellations = async () =>
  await axios
    .get(`${API}/wines/appellations`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createAppellation = async (data = {}) =>
  await axios
    .post(`${API}/wines/appellations/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getAppellation = async ({ id }) =>
  await axios
    .get(`${API}/wines/appellations/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateAppellation = async (data = {}) =>
  await axios
    .post(`${API}/wines/appellations/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteAppellation = async (data = {}) =>
  await axios
    .delete(`${API}/wines/appellations/id/${data.id}`, {
      withCredentials: true,
      data,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

/**** CLASSIFICATIONS *****/
export const getClassifications = async () =>
  await axios
    .get(`${API}/classifications`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createClassification = async (data = {}) =>
  await axios
    .post(`${API}/classifications/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getClassification = async ({ id }) =>
  await axios
    .get(`${API}/classifications/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateClassification = async (data = {}) =>
  await axios
    .post(`${API}/classifications/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteClassification = async (data = {}) =>
  await axios
    .delete(`${API}/classifications/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

/**** COUNTRIES *****/
export const getCountries = async () =>
  await axios
    .get(`${API}/countries`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createCountry = async (data = {}) =>
  await axios
    .post(`${API}/countries/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getCountry = async ({ id }) =>
  await axios
    .get(`${API}/countries/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateCountry = async (data = {}) =>
  await axios
    .post(`${API}/countries/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteCountry = async (data = {}) =>
  await axios
    .delete(`${API}/countries/id/${data.id}`, {data, 
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

/**** REGIONS *****/
export const getRegions = async () =>
  await axios
    .get(`${API}/countries/regions`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createRegion = async (data = {}) =>
  await axios
    .post(`${API}/countries/regions/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getRegion = async ({ id }) =>
  await axios
    .get(`${API}/countries/regions/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateRegion = async (data = {}) =>
  await axios
    .post(`${API}/countries/regions/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteRegion = async (data = {}) =>
  await axios
    .delete(`${API}/countries/regions/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

/**** PRODUCERS *****/
export const getProducers = async () =>
  await axios
    .get(`${API}/producers`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createProducer = async (data = {}) =>
  await axios
    .post(`${API}/producers/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getProducer = async ({ id }) =>
  await axios
    .get(`${API}/producers/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateProducer = async (data = {}) =>
  await axios
    .post(`${API}/producers/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteProducer = async (data = {}) =>
  await axios
    .delete(`${API}/producers/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

/**** GRAPES *****/
export const getGrapes = async () =>
  await axios
    .get(`${API}/wines/grapes`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createGrape = async (data = {}) =>
  await axios
    .post(`${API}/wines/grapes/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getGrape = async ({ id }) =>
  await axios
    .get(`${API}/wines/grapes/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateGrape = async (data = {}) =>
  await axios
    .post(`${API}/wines/grapes/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteGrape = async (data = {}) =>
  await axios
    .delete(`${API}/wines/grapes/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

/**** COLOURS *****/
export const getColours = async () =>
  await axios
    .get(`${API}/wines/colours`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createColour = async (data = {}) =>
  await axios
    .post(`${API}/wines/colours/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getColour = async ({ id }) =>
  await axios
    .get(`${API}/wines/colours/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateColour = async (data = {}) =>
  await axios
    .post(`${API}/wines/colours/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteColour = async (data = {}) =>
  await axios
    .delete(`${API}/wines/colours/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

/**** WINE TYPES *****/
export const getTypes = async () =>
  await axios
    .get(`${API}/wines/types`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createType = async (data = {}) =>
  await axios
    .post(`${API}/wines/types/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getType = async ({ id }) =>
  await axios
    .get(`${API}/wines/types/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const updateType = async (data = {}) =>
  await axios
    .post(`${API}/wines/types/update/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteType = async (data = {}) =>
  await axios
    .delete(`${API}/wines/types/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

    /**** WINE CONCEPTS *****/
export const getConcepts = async () =>
await axios
  .get(`${API}/wines/concepts`, {
    withCredentials: true,
  })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if (!error.response.data) {
      return {
        error: true,
        message: "Server request error",
      };
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error",
    };
  });

export const createConcept = async (data = {}) =>
await axios
  .post(`${API}/wines/concepts/create`, data, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {
    if (!error.response.data) {
      return {
        error: true,
        message: "Server request error",
      };
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error",
    };
  });

export const getConcept = async ({ id }) =>
await axios
  .get(`${API}/wines/concepts/id/${id}`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if (!error.response.data) {
      return {
        error: true,
        message: "Server request error",
      };
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error",
    };
  });

export const updateConcept = async (data = {}) =>
await axios
  .post(`${API}/wines/concepts/update/${data.id}`, data, {
    withCredentials: true,
  })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {
    if (!error.response.data) {
      return {
        error: true,
        message: "Server request error",
      };
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error",
    };
  });

export const deleteConcept = async (data = {}) =>
await axios
  .delete(`${API}/wines/concepts/id/${data.id}`, data, {
    withCredentials: true,
  })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error, t) {
    if (!error.response.data) {
      return {
        error: true,
        message: "Server request error",
      };
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error",
    };
  });