import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Tabs, Form, Input, message, Popconfirm, Divider} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined} from '@ant-design/icons';
import {Blog} from '@api';
import slugify from 'slugify';
import {filter, propEq} from 'ramda';

const {TabPane} = Tabs;

export default class EditCategory extends PureComponent{

  state = {
    isLoading: true,
    category: {
      id: null,
      name: null,
      updated_at: null,
    },
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    const _res = await Blog.getCategory({id});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      category: _res.data.category
    });

  }

  onSave = async values => {

    const slug = slugify(values.name.en) || ''

    const _res = await Blog.updateCategory({
      id: this.state.category.id,
      ...values,
      slug: slug.toLowerCase()
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Blog.deleteCategory({id: this.state.category.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/blog/categories";
    });

  }

  render(){

    const {isLoading, category} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="category"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={category}
          >

            <PageHeader
              title="Edit Category"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Tabs defaultActiveKey="en" >
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Category name"
                  name={['name', 'en']}
                  rules={[{ required: true, message: 'Please input category name' }]}
                >
                  <Input placeholder="Enter category name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Category name"
                  name={['name', 'ru']}
                  rules={[{ required: true, message: 'Please input category name' }]}
                >
                  <Input placeholder="Enter category name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Category name"
                  name={['name', 'es']}
                  rules={[{ required: true, message: 'Please input category name' }]}
                >
                  <Input placeholder="Enter category name" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Category name"
                  name={['name', 'cn']}
                  rules={[{ required: true, message: 'Please input category name' }]}
                >
                  <Input placeholder="Enter category name" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}