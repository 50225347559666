import configs from "@global/configs";
import axios from "axios";

const API = configs.api;

export const getRecommendations = async () =>
  await axios
    .get(`${API}/recommendations`, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const getRecommendation = async ({ id }) =>
  await axios
    .get(`${API}/recommendations/id/${id}`, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const createRecommendation = async (data = {}) =>
  await axios
    .post(`${API}/recommendations/create`, data, { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });

export const deleteRecommendation = async (data = {}) =>
  await axios
    .delete(`${API}/recommendations/id/${data.id}`, data, {
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error, t) {
      if (!error.response.data) {
        return {
          error: true,
          message: "Server request error",
        };
      }

      return {
        error: true,
        message: error.response.data.message || "Server request error",
      };
    });
