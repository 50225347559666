import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Select, Form, Input, message, Tabs, Divider, Upload, Switch} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, InboxOutlined} from '@ant-design/icons';
import {NativePages, StaticAPI} from '@api';
import {pathOr} from 'ramda';
import configs from '@global/configs';

const {TabPane} = Tabs;
const {TextArea} = Input;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;

export default class HomePage extends PureComponent{

  state = {
    isLoading: true,
    page: {
      id: null,
      page_id: null,
      content: null,
      title: null,
      meta: null
    },
    specialFileList: [],
    sliderFileList: [],
  }

  async componentDidMount(){
    const _res = await NativePages.getPage({page_id: 1});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    const specialFileList = pathOr(null, ['content', 'special', 'image'], _res.data.page) && [{
      uid: `uid-image-1`,
      url: `${configs.api}/static/img/${_res.data.page.content.special.image}`,
      name: 'Special Block Image',
      status: 'done',
      response: {
        data: {
          filename: _res.data.page.content.special.image
        }
      }
    }] || [];

    const sliderFileList = pathOr([], ['content', 'slider'], _res.data.page).map((img, i)=>{
      return {
        uid: `uid-${i}`,
        url: configs.api + '/static/img/' + img,
        name: `Image ${i+1}`,
        status: 'done',
        response: {
          data: {
            filename: img
          }
        }
      }
    });

    _res.data.page.imgblocks = pathOr([], ['content', 'imgblocks'], _res.data.page).map((block, i)=>{
      return {
        ...block,
        img: block.img ? {
          file: {
            uid: `uid-imgblock-${i}`,
            url: `${configs.api}/static/img/${block.img}`,
            name: 'Block Image',
            status: 'done',
            response: {
              data: {
                filename: block.img
              }
            }
          }
        } : null
      }
    })

    this.setState({
      isLoading: false,
      page: _res.data.page,
      specialFileList,
      sliderFileList
    });

  }

  onSave = async values => {

    //SAFE IMAGE PICK
    values.content.imgblocks = pathOr([], ['content', 'imgblocks'], values).map(block => {
      return {
        ...block,
        img: pathOr(null, ['img', 'file', 'response', 'data', 'filename'], block) || block.img
      }
    })

    values.content.special.image = pathOr('', ['specialFileList', 0, 'response', 'data', 'filename'], this.state); 

    values.content.slider = pathOr([], ['sliderFileList'], this.state).map(data => {
      return pathOr('', ['response', 'data', 'filename'], data)
    })

    const _res = await NativePages.updatePage({
      id: this.state.page.id,
      ...values,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  onSingleImageUpload = (info, field) => {
    const { status, response } = info.file;

    this.setState({
      [field]: [{
        ...info.file,
        url: `${configs.api}/static/img/${pathOr('', ['response', 'data', 'filename'], info.file)}`
      }]
    })

    if (status === 'done') {

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }

      this.setState({
        page: {
          ...this.state.page,
          content: {
            ...this.state.page.content,
            [field]: pathOr('', ['data', 'filename'], response)
          }
        }
      })
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }

    if(status ==='removed'){
      this.setState({
        [field]: [],
      })
    }
  }

  onSingleImageRemove = async (e, field) => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      this.setState({
        [field]: []
      })

      message.success("File deleted", 1)
    }

  }

  onMultipleImagesUpload = (info, field) => {
    const { status, response } = info.file;

    this.setState({
      [field]: [...info.fileList]
    })

    if (status === 'done') {

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }
  }

  onMultipleImagesRemove = async (e, field) => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      message.success("File deleted", 1)
    }

  }

  render(){

    const {isLoading, page, specialFileList, sliderFileList} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="page"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={page}
          >

            <PageHeader
              title="Edit Homepage"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Tabs defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Page title"
                  name={['title', 'en']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Enter page title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Page title"
                  name={['title', 'ru']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Enter page title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Page title"
                  name={['title', 'es']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Enter page title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Page title"
                  name={['title', 'cn']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Enter page title" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <h3>Slider Images</h3>
            <p style={{marginBottom: 20}}>Upload homepage slider images. Recommended image size: 1600x450px (or 3200x900px for retina)</p>

            <Form.Item name={['content', 'slider']} noStyle>
              <Dragger
                listType="picture"
                fileList={sliderFileList}
                onChange={e => this.onMultipleImagesUpload(e, 'sliderFileList')}
                onRemove={e => this.onMultipleImagesRemove(e, 'sliderFileList')}
                withCredentials
                multiple
                accept="image/*"
                name="img"
                action={`${configs.api}/static/img/upload`}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Upload up to 3 images.</p>
              </Dragger>
            </Form.Item>

            <Divider />
            
            <h3>Image/Text Blocks</h3>
            
            <Form.List name={['content', 'imgblocks']}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, i) => (

                      <div className="row">
                        <div className="col-12">
                          <Button style={{marginTop: 20, marginBottom: 20}} type="dashed" onClick={() => {
                            remove(field.name);
                          }} icon={<DeleteOutlined />}>Remove Block</Button>
                        </div>
                        <div className="col-12 col-lg-8">
                          <Tabs key={`imgblock-tab-${i}`} defaultActiveKey="en">

                            <TabPane forceRender tab="English (primary)" key="en">
                              <Form.Item
                                {...field}
                                key={`imgblock-title-en-${i}`}
                                label="Block title"
                                name={[field.name, 'title', 'en']}
                              >
                                <Input placeholder="Enter page title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`imgblock-text-en-${i}`}
                                label="Block text"
                                name={[field.name, 'text', 'en']}
                              >
                                <TextArea rows={4} placeholder="Enter block text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Russian" key="ru">
                              <Form.Item
                                {...field}
                                key={`imgblock-title-ru-${i}`}
                                label="Block title"
                                name={[field.name, 'title', 'ru']}
                              >
                                <Input placeholder="Enter page title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`imgblock-text-ru-${i}`}
                                label="Block text"
                                name={[field.name, 'text', 'ru']}
                              >
                                <TextArea rows={4} placeholder="Enter block text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Spanish" key="es">
                              <Form.Item
                                {...field}
                                key={`imgblock-title-es-${i}`}
                                label="Block title"
                                name={[field.name, 'title', 'es']}
                              >
                                <Input placeholder="Enter page title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`imgblock-text-es-${i}`}
                                label="Block text"
                                name={[field.name, 'text', 'es']}
                              >
                                <TextArea rows={4} placeholder="Enter block text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Chinese" key="cn">
                              <Form.Item
                                {...field}
                                key={`imgblock-title-cn-${i}`}
                                label="Block title"
                                name={[field.name, 'title', 'cn']}
                              >
                                <Input placeholder="Enter page title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`imgblock-text-cn-${i}`}
                                label="Block text"
                                name={[field.name, 'text', 'cn']}
                              >
                                <TextArea rows={4} placeholder="Enter block text" />
                              </Form.Item>
                            </TabPane>

                          </Tabs>
                        </div>
                        <div className="col-12 col-lg-4">

                          <h4 style={{marginBottom: 20}}>Block Image</h4>

                          <Form.Item key={`imgblock-img-${i}`} {...field} name={[field.name, 'img']} noStyle>
                            <Upload
                              listType="text"
                              withCredentials
                              accept="image/*"
                              name="img"
                              action={`${configs.api}/static/img/upload`}
                            >
                              <Button>
                                <UploadOutlined /> Upload New Image
                              </Button>
                            </Upload>
                          </Form.Item>

                        </div>
                      </div>

                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Block
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <Divider />

            <h3>Special Block (Coming Soon)</h3>

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Block Title"
                  name={['content', 'special', 'title']}
                >
                  <Input placeholder="Input block title" />
                </Form.Item>

                <Form.Item
                  label="Block Text (main)"
                  name={['content', 'special', 'text']}
                >
                  <TextArea rows={2} placeholder="Input main block text" />
                </Form.Item>

                <div className="row">
                  <div className="col-12 col-lg-6">
                    <span style={{display: 'block', marginBottom: 20}}>Special block image</span>
                    <Form.Item name={['content', 'special', 'image']}>
                      <Upload
                        listType="picture-card"
                        fileList={specialFileList}
                        withCredentials
                        accept="image/*"
                        name="img"
                        action={`${configs.api}/static/img/upload`}
                        onChange={e => this.onSingleImageUpload(e, 'specialFileList')}
                        onRemove={e => this.onSingleImageRemove(e, 'specialFileList')}
                      >
                        {specialFileList.length === 0 && <div className="ant-upload-text">Upload Image</div>}
                      </Upload>
                    </Form.Item>
                  </div>
                  <div className="col-12 col-lg-6">
                    <Form.Item
                      label="Show block?"
                      name={['content', 'special', 'is_shown']}
                    >
                      <Switch defaultChecked={pathOr(false, ['content', 'special', 'is_shown'], page)} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Block Quote Title"
                  name={['content', 'special', 'quote_title']}
                >
                  <Input placeholder="Input block title" />
                </Form.Item>

                <Form.Item
                  label="Block Quote Subtitle"
                  name={['content', 'special', 'quote_subtitle']}
                >
                  <Input placeholder="Input block subtitle" />
                </Form.Item>

                <Form.Item
                  label="Block Text (bottom)"
                  name={['content', 'special', 'text_bottom']}
                >
                  <TextArea rows={2} placeholder="Input bottom text" />
                </Form.Item>
              </div>
            </div>

            <Divider />

            <h3>Meta Information</h3>
            <p style={{marginBottom: 20}}>Meta information is for Search Engines (title, description, tags). Please note, not all search engines will necesseraly use this data directly.</p>

            <Tabs key={`meta-tabs`} defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'en', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'en', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'en', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'ru', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'ru', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'ru', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'es', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'es', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'es', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'cn', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'cn', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'cn', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Show Contact Form"
                  name={['content', 'show_form']}
                >
                  <Switch defaultChecked={pathOr(false, ['content', 'show_form'], page)} />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Show Instagram Feed"
                  name={['content', 'show_instagram']}
                >
                  <Switch defaultChecked={pathOr(false, ['content', 'show_instagram'], page)} />
                </Form.Item>
              </div>
            </div>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>
              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}