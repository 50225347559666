import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Select, Form, Input, message, Tabs, Divider, Upload, Switch} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, InboxOutlined} from '@ant-design/icons';
import {NativePages, Attributes} from '@api';
import {filter, pathOr, propEq} from 'ramda';
import configs from '@global/configs';

const {TabPane} = Tabs;
const {TextArea} = Input;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;

export default class FoodCatalogPage extends PureComponent{

  state = {
    isLoading: true,
    page: {
      id: null,
      page_id: null,
      content: null,
      title: null,
      meta: null
    },
    attributes: []
  }

  async componentDidMount(){
    const _res = await NativePages.getPage({page_id: 6});
    const _resAttributes = await Attributes.getAttributes();

    if(_res.error || _resAttributes.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      page: _res.data.page,
      attributes: _resAttributes.data.attributes
    });

  }

  onSave = async values => {
    const _res = await NativePages.updatePage({
      id: this.state.page.id,
      ...values,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  getAttributeOptions = () => {

    const {attributes} = this.state;
  
    if(attributes.length === 0){
      return [];
    }

    return attributes.map((a)=>{
      return a.type !== 'text' && <Option label={pathOr('N/a', ['name', 'en'], a)} key={a.id} value={a.id}>{pathOr('N/a', ['name', 'en'], a)}</Option>
    })

  }

  render(){

    const {isLoading, page} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="page"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={page}
          >

            <PageHeader
              title="Edit Food Catalog Page"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Tabs defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Title"
                  name={['title', 'en']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Title"
                  name={['title', 'ru']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Title"
                  name={['title', 'es']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Title"
                  name={['title', 'cn']}
                  rules={[{ required: true, message: 'Please input page title' }]}
                >
                  <Input placeholder="Input page title" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />
            
            <h3>Filters on page</h3>
            <p>Decide what filters to show on Catalog page</p>
            
            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Filters to show"
                  name={['content', 'filters']}
                  rules={[{ required: true, message: 'Please input filters to show' }]}
                >
                  <Select mode="multiple" placeholder="Select multiple filters to show">
                    <Option value="producer">Producer</Option>
                    <Option value="producer_country">Producer Country</Option>
                    <Option value="project">Project</Option>
                    <Option value="own_production">Own Production</Option>
                    <Option value="volume">Volume</Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Additional Filters to show"
                  name={['content', 'additional_filters']}
                >
                  <Select mode="multiple" placeholder="Select multiple filters to show">
                    {this.getAttributeOptions()}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <Divider />

            <h3>Meta Information</h3>
            <p style={{marginBottom: 20}}>Meta information is for Search Engines (title, description, tags). Please note, not all search engines will necesseraly use this data directly.</p>

            <Tabs key={`meta-tabs`} defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'en', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'en', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'en', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'ru', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'ru', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'ru', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'es', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'es', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'es', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'cn', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'cn', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'cn', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>
            </Tabs>

            <Form.Item
              label="Show Contact Form on Food Details Page"
              name={['content', 'show_form']}
            >
              <Switch defaultChecked={pathOr(false, ['content', 'show_form'], page)} />
            </Form.Item>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>
              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}