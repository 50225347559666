import configs from '@global/configs';
import axios from 'axios';

const API = configs.api;

export const deleteImage = async (name) => await axios.delete(`${API}/static/img/${name}`, { withCredentials: true })
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });

export const uploadArticleImage = async (img) => await axios.post(`${API}/static/img/upload`, img, { withCredentials: true })
  .then(function (response) {
    return {
      success: 1,
      file: {
        url: `${configs.api}/static/img/${response.data.data.filename}`,
        filename: response.data.data.filename
      }
    }
  })
  .catch(function (error) {
    if(!error.response.data){
      return {
        error: true,
        message: "Server request error"
      }
    }

    return {
      error: true,
      message: error.response.data.message || "Server request error"
    }
  });