import React, { PureComponent } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import slugify from 'slugify';
import {PageHeader, Button, Card, Tabs, Select, Form, Input, message, Divider, Switch, Upload, Popconfirm} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, InboxOutlined, DeleteOutlined} from '@ant-design/icons';
import {Countries, Projects, Classifications, StaticAPI} from '@api';
import {filter, propEq, pathOr, without} from 'ramda';
import configs from '@global/configs';

const {TextArea} = Input;
const {TabPane} = Tabs;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;

export default class EditProject extends PureComponent{

  state = {
    isLoading: true,
    project: {
      id: null,
      name: '',
      info: {},
      images: [],
      slug: '',
      region_id: null,
      classification_id: null,
      subclassification_id: null,
      country_id: null,
      meta: {},
      logo: '',
      is_published: false
    },
    countries: [],
    regions: [],
    classifications: [],
    slug: '',
    imgFileList: [],
    logoFileList: []
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    const project = await Projects.getProject({id});
    const countries = await Countries.getCountries();
    const regions = await Countries.getRegions();
    const classifications = await Classifications.getClassifications();

    if(project.error || countries.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    project.data.project.images = project.data.project.images || [];   

    const imgFileList = project.data.project.images.map((img, i)=>{
      return {
        uid: `uid-${i}`,
        url: configs.api + '/static/img/' + img,
        name: `Image ${i+1}`,
        status: 'done',
        response: {
          data: {
            filename: img
          }
        }
      }
    });

    const logoFileList = project.data.project.logo && [{
      uid: `uid-logo-1`,
      url: `${configs.api}/static/img/${project.data.project.logo}`,
      name: 'Logo',
      status: 'done',
      response: {
        data: {
          filename: project.data.project.logo
        }
      }
    }] || [];

    this.setState({
      isLoading: false,
      project: project.data.project,
      countries: countries.data.countries,
      regions: regions.data.regions,
      classifications: classifications.data.classifications,
      imgFileList,
      logoFileList
    });

  }

  onSave = async values => {
    const images = pathOr([], ['images'], this.state.project);
    const logo = pathOr(null, ['logo'], this.state.project);

    const _projectUpdate = await Projects.updateProject({
      ...values,
      classification_id: values.classification_id === undefined ? null : values.classification_id,
      region_id: values.region_id === undefined ? null : values.region_id,
      id: this.state.project.id,
      slug: this.state.project.slug,
      images,
      logo,
    });

    if(_projectUpdate.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  generateSlug = (e) =>{
    e.preventDefault();
    const val = e.target.value || '';
    const slug = slugify(val) || '';

    this.setState({
      project: {
        ...this.state.project,
        slug: slug.toLowerCase() || ''
      }
    });
    
  }

  getCountryOptions = () => {
    const {countries} = this.state;
    
    if(countries.length === 0){
      return [];
    }

    return countries.map(c=>{
      return {
        value: c.id,
        label: c.name.en
      }
    })
  }

  getRegionOptions = () => {
    const {regions} = this.state;
    
    if(regions.length === 0){
      return [];
    }

    return regions.map(c=>{
      return {
        value: c.id,
        label: `${c.name.en} (${pathOr('', ['name', 'es'],c)})`
      }
    })
  }

  getProjectImages = () => {

    const {images} = this.state.project;

    if(!images){
      return []
    }

    return images.map((img, i)=>{
      return {
        uid: `uid-${img.id}`,
        id: img.id,
        name: `Image ${i+1}`,
        status: 'done',
        url: `${configs.api}/${img.url}`
      }
    });
  }

  onImageUploadChange = info => {
    const { status, response } = info.file;

    this.setState({
      imgFileList: [...info.fileList]
    })

    if (status === 'done') {
      
      let {images} = this.state.project;
      
      if(!images){
        images = []
      }

      images.push(response.data.filename);

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }

      this.setState({
        project: {
          ...this.state.project,
          images
        }
      })
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }
  }

  onLogoUploadChange = info => {
    const { status, response } = info.file;

    this.setState({
      logoFileList: [{
        ...info.file,
        url: `${configs.api}/static/img/${pathOr('', ['response', 'data', 'filename'], info.file)}`
      }]
    })

    if (status === 'done') {

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }

      this.setState({
        project: {
          ...this.state.project,
          logo: response.data.filename
        }
      })
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }

    if(status ==='removed'){
      this.setState({
        logoFileList: []
      })
    }
  }

  onImageRemove = async e => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      const images = this.state.project.images || [];
      const newImages = without([name], images);

      const projectUpdated = await Projects.updateProject({
        id: this.state.project.id,
        images: newImages
      });

      this.setState({
        project: {
          ...this.state.project,
          images: newImages
        }
      })

      message.success("File deleted", 1)
    }

  }

  onLogoRemove = async e => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      const _projectUpdated = await Projects.updateProject({
        id: this.state.project.id,
        logo: ''
      });

      if(_projectUpdated.error){
        message.error("Server error, please try again")
        return;
      }

      this.setState({
        project: {
          ...this.state.project,
          logo: ''
        },
        logoFileList: []
      })

      message.success("File deleted", 1)
    }

  }

  getClassificationOptions = () => {
    const {classifications} = this.state;
    const superClassifications = filter(propEq('parent_id', null))(classifications)
    const getSubclass = parentId => classifications.map(c => c.parent_id === parentId && <Option key={c.id} value={c.id}>{c.name}</Option>)

    if(superClassifications.length === 0){
      return [];
    }

    return superClassifications.map(c =>{

      if(filter(propEq('parent_id', c.id))(classifications).length === 0){
        return <Option key={c.id} value={c.id}>{c.name}</Option>
      }

      return <OptGroup key={c.id} label={c.name}>{getSubclass(c.id)}</OptGroup>
    })
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Projects.deleteProject({id: this.state.project.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/projects";
    });

  }

  render(){

    const {isLoading, project, imgFileList, logoFileList} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="project"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={project}
          >

            <PageHeader
              title="Edit project"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Form.Item
              label="Is published?"
              name="is_published"
            >
              <Switch defaultChecked={project.is_published || false} />
            </Form.Item>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input project name' }]}
            >
              <Input onChange={this.generateSlug} placeholder="Enter project name" />
            </Form.Item>

            <Tabs defaultActiveKey="en" >
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Info"
                  name={['info', 'en']}
                  rules={[{ required: true, message: 'Please input project info' }]}
                >
                  <TextArea rows={4} placeholder="Enter project info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Info"
                  name={['info', 'ru']}
                >
                  <TextArea rows={4} placeholder="Enter project info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Info"
                  name={['info', 'es']}
                >
                  <TextArea rows={4} placeholder="Enter project info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Info"
                  name={['info', 'cn']}
                >
                  <TextArea rows={4} placeholder="Enter project info" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <Form.Item
              label="Slug"
              name="slug"
              initialValue={pathOr('', 'slug')(project)}
            >
              <Input disabled placeholder="(generates automatically)" />
            </Form.Item>

            <div className="row">
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Country"
                  name="country_id"
                  rules={[{ required: true, message: 'Please choose an option' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getCountryOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Region"
                  name="region_id"
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getRegionOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Classification"
                  name="classification_id"
                >
                  <Select
                    showSearch
                    style={{width: '100%'}}
                    placeholder="Select classification" 
                    allowClear
                    optionFilterProp="label"
                  >
                    {this.getClassificationOptions()}
                  </Select>
                </Form.Item>
              </div>
            </div>

            <Divider />
              <Form.Item
                label="External Website"
                name="website"
              >
                <Input type="url" placeholder="Enter external website URL" />
              </Form.Item>

            <Divider />

            <h3>YouTube Embedded URLs</h3>
            <p>Paste YouTube Embedded URLs in the format <i>{"<iframe ...></iframe>"}</i>:
              <br/>1. Go to YouTube video
              <br/>2. Under video, click on Share
              <br/>3. Choose embed and copy the code snippet
              <br/>4. Paste below and press Enter
            </p>

            <Form.Item
              name="videos"
            >
              <Select mode="tags" maxTagCount={10} placeholder="Enter or paste YouTube iframe URLs" />
            </Form.Item>

            <Divider />

            <h3>Meta Information (optional)</h3>
            <p style={{marginBottom: 20}}>Only if you want to replace the page title, description and tags for search engines.</p>

            <Tabs key={`meta-tabs`} defaultActiveKey="en">
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'en', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'en', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'en', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'ru', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'ru', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'ru', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'es', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'es', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'es', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>

              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Meta Title (SEO)"
                  name={['meta', 'cn', 'title']}
                >
                  <Input placeholder="Enter meta title how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Description (SEO)"
                  name={['meta', 'cn', 'description']}
                >
                  <TextArea rows={3} placeholder="Enter meta description how it should display in search engine pages" />
                </Form.Item>

                <Form.Item
                  label="Meta Tags (SEO)"
                  name={['meta', 'cn', 'tags']}
                >
                  <Select mode="tags" placeholder="Enter tags by one"/>
                </Form.Item>
              </TabPane>
            </Tabs>

            <div className="row">
              <div className="col-12 col-lg-4">
               <Form.Item label="Logo">
                  <Form.Item name="logo" noStyle>
                    <Upload
                      listType="picture-card"
                      fileList={logoFileList}
                      withCredentials
                      accept="image/*"
                      name="img"
                      action={`${configs.api}/static/img/upload`}
                      onChange={this.onLogoUploadChange}
                      onRemove={this.onLogoRemove}
                    >
                      {logoFileList.length === 0 && <div className="ant-upload-text">Upload</div>}
                    </Upload>
                  </Form.Item>
                </Form.Item>
              </div>
              <div className="col-12 col-lg-8">
                <Form.Item label="Images">
                  <Form.Item name="images" noStyle>
                    <Dragger
                      listType="picture"
                      fileList={imgFileList}
                      onChange={this.onImageUploadChange}
                      onRemove={this.onImageRemove}
                      withCredentials
                      multiple
                      accept="image/*"
                      name="img"
                      action={`${configs.api}/static/img/upload`}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Upload up to 3 images.</p>
                    </Dragger>
                  </Form.Item>
                </Form.Item>
              </div>
            </div>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}