import React, { PureComponent } from 'react';
import {PageHeader, Button, Select, Card, Tabs, Form, Input, message, Popconfirm, Divider} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined} from '@ant-design/icons';
import {Attributes} from '@api';
import {pathOr} from 'ramda';

const {Option} = Select;
const {TabPane} = Tabs;

export default class EditProducer extends PureComponent{

  state = {
    isLoading: true,
    producer: {
      id: null,
      name: null,
      updated_at: null,
    },
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    const _res = await Attributes.getProducer({id});

    if(_res.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      producer: _res.data.producer,
    });

  }

  onSave = async values => {
    const _res = await Attributes.updateProducer({
      id: this.state.producer.id,
      ...values,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Attributes.deleteProducer({id: this.state.producer.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/attributes/producers";
    });

  }

  render(){

    const {isLoading, producer} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="producer"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={producer}
          >

            <PageHeader
              title="Edit Producer"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input producer name' }]}
            >
              <Input placeholder="Enter producer name" />
            </Form.Item>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}