import React, { PureComponent } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import slugify from 'slugify';
import {Link} from 'react-router-dom';
import {PageHeader, Button, Card, Tabs, Select, Form, Input, message, Divider, Switch, Upload, Collapse, Popconfirm} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, InboxOutlined, DeleteOutlined, PlusOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons';
import {Attributes, Countries, Projects, Wines, Producers, StaticAPI, Food} from '@api';
import {filter, propEq, pathOr, without, find, mapObjIndexed, indexOf} from 'ramda';
import configs from '@global/configs';

const {TextArea} = Input;
const {TabPane} = Tabs;
const {Panel} = Collapse;
const {Option, OptGroup} = Select;
const {Dragger} = Upload;

export default class EditWine extends PureComponent{

  state = {
    isLoading: true,
    wine: {
      id: null,
      name: '',
      slug: '',
      description: {},
      media: [],
      producer_country: null,
      region_id: null,
      preview_image: null,
      appelation_id: null,
      producer_id: null,
      availability: [],
      colour_id: {},
      grapes: [],
      project_id: null,
      year: null,
      volume: null,
      type_id: null,
      is_featured: false,
      is_published: false,
      own_production: false,
      tasting_notes: {},
      production_features: {}
    },
    producers: [],
    regions: [],
    appellations: [],
    countries: [],
    colours: [],
    types: [],
    concepts: [],
    attributes: [],
    wineAttributes: [],
    imgFileList: [],
    previewFilelist: [],
    food: [],
    wineFoodMatch: [],
  }

  async componentDidMount(){
    const id = this.props.match.params.id;

    const wine = await Wines.getWine({id});
    const attributes = await Attributes.getAttributes();
    const concepts = await Attributes.getConcepts();
    const wineAttributes = await Attributes.getWineAttributes({wine_id: id});
    const projects = await Projects.getProjects();
    const producers = await Producers.getProducers();
    const regions = await Countries.getRegions();
    const appellations = await Wines.getAppellations();
    const countries = await Countries.getCountries();
    const colours = await Wines.getColours();
    const types = await Wines.getTypes();

    const food = await Food.getAllFood();
    const wineFoodMatch = await Wines.getWineFoodMatch({id});

    if(wine.error ||
      producers.error ||
      regions.error ||
      appellations.error ||
      countries.error ||
      colours.error ||
      types.error || 
      projects.error ||
      attributes.error ||
      wineAttributes.error ||
      food.error ||
      wineFoodMatch.error ||
      concepts.errors
      ){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }
    
    wine.data.wine.media = wine.data.wine.media || [];   

    const imgFileList = wine.data.wine.media.map((img, i)=>{
      return {
        uid: `uid-${i}`,
        url: configs.api + '/static/img/' + img,
        name: `Image ${i+1}`,
        status: 'done',
        response: {
          data: {
            filename: img
          }
        }
      }
    });

    const previewFilelist = wine.data.wine.preview_image && [{
      uid: `uid-image-1`,
      url: `${configs.api}/static/img/${wine.data.wine.preview_image}`,
      name: 'Preview Image',
      status: 'done',
      response: {
        data: {
          filename: wine.data.wine.preview_image
        }
      }
    }] || [];

    this.setState({
      isLoading: false,
      wine: wine.data.wine,
      projects: projects.data.projects,
      attributes: attributes.data.attributes,
      producers: producers.data.producers,
      regions: regions.data.regions,
      appellations: appellations.data.appellations,
      countries: countries.data.countries,
      colours: colours.data.colours,
      types: types.data.types,
      attributes: attributes.data.attributes,
      wineAttributes: wineAttributes.data.wine_attributes,
      concepts: concepts.data.concepts,
      food: food.data.food,
      wineFoodMatch: wineFoodMatch.data,
      imgFileList,
      previewFilelist
    });

  }

  onSave = async values => {
    const media = pathOr([], ['media'], this.state.wine);
    const preview_image = pathOr(null, ['preview_image'], this.state.wine);
    const slug = slugify(values.name) || ''

    values.attributes = mapObjIndexed(a => a || null, values.attributes);
  
    const _wineUpdate = await Wines.updateWine({
      ...values,
      project_id: values.project_id === undefined ? null : values.project_id,
      concept_id: values.concept_id || null,
      id: this.state.wine.id,
      slug: slug.toLowerCase(),
      media,
      match: values.match || [],
      grapes: values.grapes || [],
      preview_image,
      extra_attributes: values.extra_attributes.attributes || null,
      extra_awards: values.extra_awards.awards || null,
    })

    if(_wineUpdate.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  getCountryOptions = () => {
    const {countries} = this.state;
    
    if(countries.length === 0){
      return [];
    }

    return countries.map(c=>{
      return {
        value: c.id,
        label: c.name.en
      }
    })
  }

  getRegionOptions = () => {
    const {regions} = this.state;
    
    if(regions.length === 0){
      return [];
    }

    return regions.map(c=>{
      return {
        value: c.id,
        label: c.name.en
      }
    })
  }

  getWineTypes = () => {
    const {types} = this.state;
    
    if(types.length === 0){
      return [];
    }

    return types.map(c=>{
      return {
        value: c.id,
        label: pathOr('No name', ['name', 'en'], c)
      }
    })
  }

  getColourOptions = () => {
    const {colours} = this.state;
    
    if(colours.length === 0){
      return [];
    }

    return colours.map(c=>{
      return {
        value: c.id,
        label: c.name.en
      }
    })
  }

  getAppellationOptions = () => {
    const {appellations} = this.state;
    
    if(appellations.length === 0){
      return [];
    }

    return appellations.map(c=>{
      return {
        value: c.id,
        label: c.name.en
      }
    })
  }

  getProducerOptions = () => {
    const {producers} = this.state;
    
    if(producers.length === 0){
      return [];
    }

    return producers.map(c=>{
      return {
        value: c.id,
        label: c.name
      }
    })
  }

  getProjectOptions = () => {
    const {projects} = this.state;
    
    if(projects.length === 0){
      return [];
    }

    return projects.map(c=>{
      return {
        value: c.id,
        label: c.name || 'No name'
      }
    })
  }

  getConceptOptions = () => {
    const {concepts} = this.state;
    
    if(concepts.length === 0){
      return [];
    }

    return concepts.map(c=>{
      return {
        value: c.id,
        label: c.name.en || 'No name'
      }
    })
  }

  getPanelOptions = () => {
    const {attributes, wineAttributes} = this.state;

    const getInput = panel => {

      switch (panel.type) {
        case 'tags':

          let vals = pathOr([], ['value'], find(propEq('attribute_id', panel.id))(wineAttributes));

          try {
            vals = JSON.parse(vals);
          } catch (error) {
            console.log('Not JSON');
          }

          return <Form.Item
            label={pathOr('N/a', ['name', 'en'], panel)}
            name={['attributes', 'attr-'+panel.id]}
            initialValue={vals}
          >
            <Select mode="tags" showSearch optionFilterProp="label" allowClear style={{ width: '100%' }} placeholder="Choose one or type">
              {panel.defaults.map((d,i)=>{
                return <Option key={i} value={d} label={d}>{d}</Option>
              })}
            </Select>
          </Form.Item>

        case 'select':

          let selectNormalized = pathOr([], ['value'], find(propEq('attribute_id', panel.id))(wineAttributes));

          try {
            selectNormalized = JSON.parse(selectNormalized)[0];
          } catch (error) {
            console.log('Not JSON');
          }
          
          if(selectNormalized === 'null'){
            selectNormalized = undefined;
          }

          // If not array -> to array
          if(!Array.isArray(selectNormalized)){
            selectNormalized = [selectNormalized];
          }

          return <Form.Item
            label={pathOr('N/a', ['name', 'en'], panel)}
            name={['attributes', 'attr-'+panel.id]}
            initialValue={selectNormalized}
          >
            <Select showSearch optionFilterProp="label" allowClear style={{ width: '100%' }} placeholder="Choose one">
              {panel.defaults.map((d,i)=>{
                return <Option key={i} value={[d]} label={d}>{d}</Option>
              })}
            </Select>
          </Form.Item>

        case 'text':
          return <Form.Item
            label={pathOr('N/a', ['name', 'en'], panel)}
            name={['attributes', 'attr-'+panel.id]}
            initialValue={pathOr('', ['value'], find(propEq('attribute_id', panel.id))(wineAttributes))}
          >
            <TextArea rows={4} placeholder={`Enter details for ${pathOr('N/a', ['name', 'en'], panel)}`}></TextArea>
          </Form.Item>
      
        default:
          break;
      }
    }

    return attributes.map((a, i)=>{
      return <Panel forceRender key={i} header={pathOr('N/a', ['name', 'en'], a)}>
        {getInput(a)}
      </Panel>
    });
  }
  
  getVolumeOptions = () => {
    const possibleVolumes = [
      '0,25 L',
      '0,33 L',
      '0,375 L',
      '0,5 L',
      '0,75 L',
      '1 L',
      '1,5 L',
      '2 L',
      '2,5 L',
      '3 L',
      '3,5 L',
      '4 L',
      '4,5 L',
      '5 L',
      '5,5 L',
    ]

    return possibleVolumes.map(v=>{
      return {
        value: v,
        label: v
      }
    })
  }

  getProjectImages = () => {
    const {images} = this.state.project;

    if(!images){
      return []
    }

    return images.map((img, i)=>{
      return {
        uid: `uid-${img.id}`,
        id: img.id,
        name: `Image ${i+1}`,
        status: 'done',
        url: `${configs.api}/${img.url}`
      }
    });
  }

  onImageUploadChange = info => {
    const { status, response } = info.file;

    this.setState({
      imgFileList: [...info.fileList]
    })

    if (status === 'done') {
      
      let {media} = this.state.wine;
      
      if(!media){
        media = []
      }

      media.push(response.data.filename);

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }

      this.setState({
        wine: {
          ...this.state.wine,
          media
        }
      })
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }
  }

  onPreviewUploadChange = info => {
    const { status, response } = info.file;

    this.setState({
      previewFilelist: [{
        ...info.file,
        url: `${configs.api}/static/img/${pathOr('', ['response', 'data', 'filename'], info.file)}`
      }]
    })

    if (status === 'done') {

      if(response.error){
        message.error('Uploading failed. Try another image type or size', 3);
        return;
      }

      this.setState({
        wine: {
          ...this.state.wine,
          preview_image: response.data.filename
        }
      })
      
      message.success('File uploaded', 1)
    } else if (status === 'error') {
      message.error('Uploading failed. Try another image type or size', 3)
    }

    if(status ==='removed'){
      this.setState({
        previewFilelist: []
      })
    }
  }

  onImageRemove = async e => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      const media = this.state.wine.media || [];
      const newMedia = without([name], media);

      const wineUpdated = await Wines.updateWine({
        id: this.state.wine.id,
        media: newMedia
      });

      this.setState({
        wine: {
          ...this.state.wine,
          media: newMedia
        }
      })

      message.success("File deleted", 1)
    }

  }

  onPreviewRemove = async e => {
    const name = pathOr(null, ['response', 'data', 'filename'])(e);

    if(name){
      const deleted = await StaticAPI.deleteImage(name);
      if(deleted.error){
        message.error("Server error, please try again", 1)
        return;
      }

      const _wineUpdated = await Wines.updateWine({
        id: this.state.wine.id,
        preview_image: ''
      });

      if(_wineUpdated.error){
        message.error("Server error, please try again")
        return;
      }

      this.setState({
        wine: {
          ...this.state.wine,
          preview_image: ''
        },
        previewFilelist: []
      })

      message.success("File deleted", 1)
    }

  }

  getClassificationOptions = () => {
    const {classifications} = this.state;
    const superClassifications = filter(propEq('parent_id', null))(classifications)
    const getSubclass = parentId => classifications.map(c => c.parent_id === parentId && <Option key={c.id} value={c.id}>{c.name}</Option>)

    if(superClassifications.length === 0){
      return [];
    }

    return superClassifications.map(c =>{

      if(filter(propEq('parent_id', c.id))(classifications).length === 0){
        return <Option key={c.id} value={c.id}>{c.name}</Option>
      }

      return <OptGroup key={c.id} label={c.name}>{getSubclass(c.id)}</OptGroup>
    })
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Wines.deleteWine({id: this.state.wine.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/wines";
    });

  }

  getMatchOptions = () => {
    const {food} = this.state;

    return food.map(f => {
      return {
        value: f.id,
        label: `ID-${f.id}: ${f.name}`
      }
    });
  }

  render(){

    const {isLoading, wine, imgFileList, previewFilelist, wineFoodMatch} = this.state;

    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="wine"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={wine}
          >

            <PageHeader
              title="Edit wine"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Is published?"
                  name="is_published"
                >
                  <Switch defaultChecked={wine.is_published || false} />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Own production?"
                  name="own_production"
                >
                  <Switch defaultChecked={wine.own_production || false} />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: 'Please input wine name' }]}
            >
              <Input size="large" placeholder="Enter wine name" />
            </Form.Item>

            <Tabs defaultActiveKey="en" >
              <TabPane forceRender tab="English (primary)" key="en">
                <Form.Item
                  label="Description"
                  name={['description', 'en']}
                >
                  <TextArea rows={4} placeholder="Enter wine info" />
                </Form.Item>
                <Form.Item
                  label="Tasting Notes"
                  name={['tasting_notes', 'en']}
                >
                  <TextArea rows={4} placeholder="Enter tasting notes info" />
                </Form.Item>
                <Form.Item
                  label="Features of Production"
                  name={['production_features', 'en']}
                >
                  <TextArea rows={4} placeholder="Enter features of production info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Russian" key="ru">
                <Form.Item
                  label="Description"
                  name={['description', 'ru']}
                >
                  <TextArea rows={4} placeholder="Enter wine info" />
                </Form.Item>
                <Form.Item
                  label="Tasting Notes"
                  name={['tasting_notes', 'ru']}
                >
                  <TextArea rows={4} placeholder="Enter tasting notes info" />
                </Form.Item>
                <Form.Item
                  label="Features of Production"
                  name={['production_features', 'ru']}
                >
                  <TextArea rows={4} placeholder="Enter features of production info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Spanish" key="es">
                <Form.Item
                  label="Description"
                  name={['description', 'es']}
                >
                  <TextArea rows={4} placeholder="Enter wine info" />
                </Form.Item>
                <Form.Item
                  label="Tasting Notes"
                  name={['tasting_notes', 'es']}
                >
                  <TextArea rows={4} placeholder="Enter tasting notes info" />
                </Form.Item>
                <Form.Item
                  label="Features of Production"
                  name={['production_features', 'es']}
                >
                  <TextArea rows={4} placeholder="Enter features of production info" />
                </Form.Item>
              </TabPane>
              <TabPane forceRender tab="Chinese" key="cn">
                <Form.Item
                  label="Description"
                  name={['description', 'cn']}
                >
                  <TextArea rows={4} placeholder="Enter wine info" />
                </Form.Item>
                <Form.Item
                  label="Tasting Notes"
                  name={['tasting_notes', 'cn']}
                >
                  <TextArea rows={4} placeholder="Enter tasting notes info" />
                </Form.Item>
                <Form.Item
                  label="Features of Production"
                  name={['production_features', 'cn']}
                >
                  <TextArea rows={4} placeholder="Enter features of production info" />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Divider />

            <div className="row">
              <div className="col-12 col-lg-12">
                <Form.Item
                  label="Slug"
                  name="slug"
                >
                  <Input disabled placeholder="(generates automatically)" style={{ width: '100%' }} />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Project"
                  name="project_id"
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getProjectOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Wine Concept"
                  name="concept_id"
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getConceptOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Wine Type"
                  name="type_id"
                  rules={[{ required: true, message: 'Please choose wine type' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getWineTypes()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Producer"
                  name="producer_id"
                  rules={[{ required: true, message: 'Please choose producer' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getProducerOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Appellation"
                  name="appellation_id"
                  rules={[{ required: true, message: 'Please choose appellation' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getAppellationOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Producer Country"
                  name="producer_country_id"
                  rules={[{ required: true, message: 'Please choose an option' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getCountryOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Region"
                  name="region_id"
                  rules={[{ required: true, message: 'Please choose region' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getRegionOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Wine colour"
                  name="colour_id"
                  rules={[{ required: true, message: 'Please choose colour' }]}
                >
                  <Select showSearch optionFilterProp="label" allowClear options={this.getColourOptions()} style={{ width: '100%' }} placeholder="Choose one" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-4">
                <Form.Item
                  label="Volume options"
                  name="volume"
                >
                  <Select showSearch allowClear mode="tags" optionFilterProp="label" options={this.getVolumeOptions()} style={{ width: '100%' }} placeholder="Choose or type" />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Grapes/Variety"
                  name="grapes"
                >
                  <Select showSearch allowClear mode="tags" optionFilterProp="label" style={{ width: '100%' }} placeholder="Type multiple and press enter" />
                </Form.Item>
              </div>
            </div>

            <Divider />

            <h3>Images</h3>
            <p style={{marginBottom: 20}}>Add preview and additional images</p>

            <div className="row">
              <div className="col-12 col-lg-4">
               <Form.Item label="Preview Image">
                  <Form.Item name="preview_image" noStyle>
                    <Upload
                      listType="picture-card"
                      fileList={previewFilelist}
                      withCredentials
                      accept="image/*"
                      name="img"
                      action={`${configs.api}/static/img/upload`}
                      onChange={this.onPreviewUploadChange}
                      onRemove={this.onPreviewRemove}
                    >
                      {previewFilelist.length === 0 && <div className="ant-upload-text">Upload</div>}
                    </Upload>
                  </Form.Item>
                </Form.Item>
              </div>
              <div className="col-12 col-lg-8">
                <Form.Item label="Images">
                  <Form.Item name="media" noStyle>
                    <Dragger
                      listType="picture"
                      fileList={imgFileList}
                      onChange={this.onImageUploadChange}
                      onRemove={this.onImageRemove}
                      withCredentials
                      multiple
                      accept="image/*"
                      name="img"
                      action={`${configs.api}/static/img/upload`}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">Upload up to 3 images.</p>
                    </Dragger>
                  </Form.Item>
                </Form.Item>
              </div>
            </div>

            <Divider />

            <Form.Item
              label="Recommended food with this wine"
              name="match"
              initialValue={wineFoodMatch.ids || []}
            >
              <Select showSearch allowClear options={this.getMatchOptions()} mode="multiple" optionFilterProp="label" style={{ width: '100%' }} placeholder="Choose food to recommend with this wine" />
            </Form.Item>

            <Divider />

            <h3>Additional information</h3>
            <p style={{marginBottom: 20}}>Edit <Link to="/attributes/custom">additional</Link> wine attributes.</p>

            <Collapse defaultActiveKey={['0']} style={{margin: '25px 0 50px 0'}}>
              {this.getPanelOptions()}
            </Collapse>

            <Divider />

            <h3>Extra attributes</h3>
            <p style={{marginBottom: 20}}>Extra attributes will be shown on Wine page after main attributes</p>

            <Form.List name={['extra_attributes', 'attributes']}>
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, i) => (

                      <div className="row">
                        <div className="col-12">
                          <Button style={{marginTop: 20, marginBottom: 20}} type="dashed" onClick={() => {
                            remove(field.name);
                          }} icon={<DeleteOutlined />}>Remove Block</Button>
                        </div>
                        <div className="col-12">
                          <Tabs key={`extra-attr-tab-${i}`} defaultActiveKey="en">

                            <TabPane forceRender tab="English (primary)" key="en">
                              <Form.Item
                                {...field}
                                key={`extra-attr-title-en-${i}`}
                                label="Attribute name"
                                name={[field.name, 'title', 'en']}
                              >
                                <Input placeholder="Enter title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`extra-attr-text-en-${i}`}
                                label="Attribute text"
                                name={[field.name, 'text', 'en']}
                              >
                                <TextArea rows={4} placeholder="Enter text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Russian" key="ru">
                              <Form.Item
                                {...field}
                                key={`extra-attr-title-ru-${i}`}
                                label="Attribute title"
                                name={[field.name, 'title', 'ru']}
                              >
                                <Input placeholder="Enter title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`extra-attr-text-ru-${i}`}
                                label="Attribute text"
                                name={[field.name, 'text', 'ru']}
                              >
                                <TextArea rows={4} placeholder="Enter text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Spanish" key="es">
                              <Form.Item
                                {...field}
                                key={`extra-attr-title-es-${i}`}
                                label="Attribute title"
                                name={[field.name, 'title', 'es']}
                              >
                                <Input placeholder="Enter title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`extra-attr-text-es-${i}`}
                                label="Attribute text"
                                name={[field.name, 'text', 'es']}
                              >
                                <TextArea rows={4} placeholder="Enter text" />
                              </Form.Item>
                            </TabPane>

                            <TabPane forceRender tab="Chinese" key="cn">
                              <Form.Item
                                {...field}
                                key={`extra-attr-title-cn-${i}`}
                                label="Attribute title"
                                name={[field.name, 'title', 'cn']}
                              >
                                <Input placeholder="Enter title" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={`extra-attr-text-cn-${i}`}
                                label="Attribute text"
                                name={[field.name, 'text', 'cn']}
                              >
                                <TextArea rows={4} placeholder="Enter text" />
                              </Form.Item>
                            </TabPane>

                          </Tabs>
                        </div>
                      </div>

                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Block
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <Divider />

            <h3>Awards</h3>
            <p style={{marginBottom: 20}}>Awards will be shown on Wine page. You can sort Awards clicking Up and Down buttons</p>


            <Form.List name={['extra_awards', 'awards']}>
              {(fields, { add, remove, move }) => {

                return (
                  <div>
                    {fields.map((field, i) => (
                      <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                          <Button style={{marginTop: 20, marginBottom: 20}} type="dashed" onClick={() => {
                            remove(field.name);
                          }} icon={<DeleteOutlined />}>Remove Block</Button>
                          <div className="d-flex align-items-between">
                            <Button onClick={() => move(indexOf(field, fields), indexOf(field, fields) - 1)} style={{marginLeft: 5, marginRight: 5}} type="default" shape="circle" icon={<CaretUpOutlined />} size={20} />
                            <Button onClick={() => move(indexOf(field, fields), indexOf(field, fields) + 1)} style={{marginLeft: 5, marginRight: 5}} type="default" shape="circle" icon={<CaretDownOutlined />} size={20} />
                          </div>
                        </div>
                        <div className="col-12">
                          <Form.Item
                            {...field}
                            key={`extra-award-title-${i}`}
                            label="🏅 Award name"
                            name={[field.name]}
                          >
                            <Input placeholder="Enter title" />
                          </Form.Item>
                        </div>
                      </div>

                    ))}

                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined /> Add Block
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}