import React, { PureComponent } from 'react';
import {PageHeader, Button, Card, Select, Form, Input, message, Popconfirm, Divider} from 'antd';
import Loading from '@components/Loading/Loading'
import {SaveOutlined, DeleteOutlined} from '@ant-design/icons';
import {Attributes} from '@api';
import {filter, propEq} from 'ramda';

const {Option, OptGroup} = Select;

export default class EditClassification extends PureComponent{

  state = {
    isLoading: true,
    classifications: [],
    classification: {
      id: null,
      name: null,
      parent_id: null,
      updated_at: null,
    },
  }

  async componentDidMount(){
    const id = this.props.match.params.id;
    const _res = await Attributes.getClassification({id});
    const _resAll = await Attributes.getClassifications();

    if(_res.error || _resAll.error){
      message.error('Error getting data from server. Please try again later or contact admin.');
      return;
    }

    this.setState({
      isLoading: false,
      classification: _res.data.classification,
      classifications: _resAll.data.classifications
    });

  }

  onSave = async values => {
    const _res = await Attributes.updateClassification({
      id: this.state.classification.id,
      ...values,
      parent_id: values.parent_id || null,
    });

    if(_res.error){
      message.error('Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully updated", 1);
    return;

  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  getClassificationOptions = () => {
    const {classifications} = this.state;

    const superClassifications = filter(propEq('parent_id', null))(classifications)

    if(superClassifications.length === 0){
      return [];
    }

    return superClassifications.map(c =>{
      if(c.id !== this.state.classification.id){
        return <Option label={c.name} key={c.id} value={c.id}>{c.name}</Option>
      }
    })
  }

  onDelete = async () => {

    this.setState({
      isLoading: true
    });

    const deleted = await Attributes.deleteClassification({id: this.state.classification.id});

    this.setState({
      isLoading: false
    });

    if(deleted.error){
      message.error("Error occured. Can't delete.");
      return;
    }

    message.success("Data deleted", 1).then(()=>{
      window.location.href = "/attributes/classifications";
    });

  }

  render(){

    const {isLoading, classification} = this.state;
    
    return isLoading ? <Loading /> : <div className="row ">

      <div className="col-12 col-xl-9">
        <Card>

          <Form
            name="classification"
            layout="vertical"
            onFinish={this.onSave}
            onFinishFailed={this.onValidationError}            
            scrollToFirstError
            initialValues={classification}
          >

            <PageHeader
              title="Edit Classification"
              onBack={() => window.history.back()}
              extra={[
                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>,
              ]}
            />

            <div className="row">
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[{ required: true, message: 'Please input attribute name' }]}
                >
                  <Input placeholder="Enter attribute name" />
                </Form.Item>
              </div>
              <div className="col-12 col-lg-6">
                <Form.Item
                  label="Parent classification"
                  name="parent_id"
                >
                  <Select
                    showSearch
                    style={{width: '100%'}}
                    placeholder="Select classification (if required)"
                    allowClear
                    optionFilterProp="label"
                  >{this.getClassificationOptions()}</Select>
                </Form.Item>
              </div>
            </div>

            <Divider />

            <div className="row">
              <div className="col-12 d-flex justify-content-between align-items-center">

                <Popconfirm
                  title={<p style={{margin: 0}}><strong>Do you really want to delete?</strong><br/>All associated data (wines/food/projects) will possibly be affected.</p>}
                  onConfirm={this.onDelete}
                  okText="Delete"
                  cancelText="Cancel"
                >
                  <Button disabled={isLoading} type="danger" icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>

                <Button disabled={isLoading} htmlType="submit" type="primary" icon={<SaveOutlined />}>Save</Button>

              </div>
            </div>

          </Form>

        </Card>
        
      </div>

    </div>
  }

}