import React, { PureComponent, Fragment } from 'react';
import {Result, Button, message} from 'antd';
import {Food} from '@api';
import {Redirect} from 'react-router-dom';
import Loading from '@components/Loading/Loading';

export default class NewFood extends PureComponent{

  state = {
    _isLoading: true,
    error: false,
    newFoodId: 0,
  }

  async componentDidMount(){

    const _res = await Food.createFood();

    if(_res.error){
      message.error(_res.message || "Can't complete request due to server error. Try later.", 3);
      this.setState({
        _isLoading: false,
        error: true,
      })
      return;
    }

    this.setState({
      _isLoading: false,
      error: false,
      newFoodId: _res.data.food.id
    })

  }

  render(){

    const {_isLoading, error, newFoodId} = this.state;

    return <div className="site-layout-background">
      {_isLoading && <Loading title="Creating food…" />}
      {error && <Result
        status="error"
        title="Error"
        subTitle="We couldn't connect to server properly, please try again later or contact admin."
        extra={[
          <Button onClick={e => {e.preventDefault(); window.location.reload(false);}} type="primary" key="console">Try again</Button>,
        ]}
      />}
      {newFoodId !== 0 && <Redirect to={`/food/edit/${newFoodId}`} />}
    </div>
  }

}