import React, {PureComponent} from 'react';
import moment from 'moment';
import {PageHeader, Card, Button, message, Divider, Breadcrumb, Table, Tag, Form, Select} from 'antd';
import {Link} from 'react-router-dom';
import {DeleteOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {Recommendations, Wines} from '@api';
import configs from '@global/configs';
import {pathOr, filter, propEq} from 'ramda';

export default class RecommendationsPage extends PureComponent{

  state = {
    isLoading: true,
    recommendations: [],
    wines: [],
  }

  async componentDidMount(){
    const _resRecommendations = await Recommendations.getRecommendations();
    const _resWines = await Wines.getWines();

    if(_resRecommendations.error || _resWines.error){
      message.error(_resRecommendations.message || _resWines.message || "Can't complete request due to server error. Try later.", 3)
      return;
    }

    this.setState({
      isLoading: false,
      recommendations: _resRecommendations.data.recommendations,
      wines: _resWines.data.wines,
    })
  }

  getTableData = () => {
    const {recommendations} = this.state;

    return recommendations.map((data, i) => {

      return {
        key: data.wine_id,
        id: data.wine_id,
        preview_image: pathOr(null, ['wine.preview_image'], data),
        name: pathOr('No name', ['wine.name'], data),
        producer: pathOr(null, ['wine.producer.name'], data),
        project: pathOr(null, ['wine.project.name'], data),
        updated_at: moment(data.updated_at).utc().format('MM/DD/YYYY HH:mm'),
        extra: data.wine_id
      }
    })
  }

  getWineOptions = () => {
    const {wines} = this.state;
    
    if(wines.length === 0){
      return [];
    }

    return wines.map(wine=>{
      return {
        value: wine.id,
        label: `[ID ${wine.id}]: ${pathOr('N/a', ['name'], wine)} ${pathOr('No Producer', ['producer.name'], wine)} (${pathOr('No Project', ['project.name'], wine)})`
      }
    })
  }

  onFormSubmit = async values => {

    const _res = await Recommendations.createRecommendation({...values})

    if(_res.error){
      message.error(_res.message || 'Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Successfully created", 1).then(()=>window.location.reload());
  }

  onValidationError = err => {    
    message.error('Please input required fields', 2);
  }

  onDeleteRec = async (e, id) => {
    e.preventDefault();

    const _res = await Recommendations.deleteRecommendation({id})

    if(_res.error){
      message.error(_res.message || 'Error occured during update, please try again or contact administrator');
      return;
    }

    message.success("Removed", 1).then(()=>window.location.reload());
  }

  render(){

    const {isLoading} = this.state;

    const tablecols = [
      {
        key: 'preview_image',
        dataIndex: 'preview_image',
        render: data => data ? <img className="list-logo-img" src={`${configs.api}/static/img/${data}`} /> : <img className="list-logo-img" src="/img/no-image.png" />
      },
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
        render: data => <strong className="m-0">{data}</strong>
      },
      {
        key: 'name',
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => { return a.name.localeCompare(b.name)},
        render: data => <strong className="m-0">{data}</strong>
      },
      {
        key: 'producer',
        title: 'Producer',
        dataIndex: 'producer',
        sorter: (a, b) => { return a.producer.localeCompare(b.producer)},
        render: data => <strong className="m-0">{data}</strong>
      },
      {
        key: 'project',
        title: 'Project',
        dataIndex: 'project',
        sorter: (a, b) => { return a.project.localeCompare(b.project)},
        render: data => <Tag>{data}</Tag>
      },
      {
        key: 'updated_at',
        title: 'Last Edit',
        dataIndex: 'updated_at',
        sorter: (a, b) => moment(a.updated_at).unix() - moment(b.updated_at).unix(),
        render: data => <p className="m-0">{data}</p>
      },
      {
        key: 'extra',
        dataIndex: 'extra',
        align: 'right',
        render: id => <Button icon={<DeleteOutlined />} onClick={e => this.onDeleteRec(e, id)} type="danger">Delete</Button>
      },
    ]

    return <div>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item><Link to="/">Dashboard</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Recommendations</Breadcrumb.Item>
      </Breadcrumb>
      <Card loading={isLoading}>
        <PageHeader
          style={{padding: 0}}
          title="Recommendations"
          subTitle="Wine recommendations will circulate in Wine Details page. Usually 4 randomly chosen recommended wines will be shown to the visitor."
        />

        <Form
          name="recommendations"
          layout="vertical"
          onFinish={this.onFormSubmit}
          onFinishFailed={this.onValidationError}            
          scrollToFirstError
        >

          <Divider />

          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-8">
              <Form.Item
                label="Select wine to recommend"
                name="wine_id"
                rules={[{ required: true, message: 'Please input article title' }]}
              >
                <Select showSearch optionFilterProp="label" allowClear options={this.getWineOptions()} style={{ width: '100%' }} placeholder="Choose one" />
              </Form.Item>
            </div>
            <div className="col-12 col-lg-4">
              <Button disabled={isLoading} htmlType="submit" type="primary" icon={<PlusCircleOutlined />}>Add to recommendations</Button>
            </div>
          </div>

        </Form>

        <Divider />

        <Table pagination={{showSizeChanger: true}} loading={isLoading} columns={tablecols} dataSource={this.getTableData()} />
      </Card>
    </div>
  }
}